.horizontal-card {
  margin-bottom: 10px;
  min-height: 150px;
  position: relative;
  display: flex;
  background-color: #fff;
  box-shadow: 0 2px 7px rgba(0, 0, 0, .1);
  border-radius: 3px;
}

.horizontal-card__image {
  width: 215px;
  min-height: 150px;
  position: relative;
  display: block;
  background-repeat: no-repeat !important;
  background-position: center !important;
  background-size: cover !important;
  border: none;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 3px !important;
  border-top-left-radius: 3px !important;
  cursor: pointer;

  .image-count {
    position: absolute;
    bottom: 8px;
    right: 8px;
  }
}

.horizontal-card__tooltip {
  padding: 0;
  position: absolute;
  top: 0;
  left: 15px;
  background: none;
  border: none;

  img {
    width: 30px;
  }
}

.horizontal-card__title {
  margin-bottom: 10px;
  padding: 0;
  font-family: 'Source Sans Pro Semibold';
  font-size: 20px;
  color: $color-text--dark;
  line-height: 1;
  text-align: left;
  cursor: pointer;

  &:hover,
  &:active,
  &:focus {
    color: $color-primary;
    text-decoration: underline;
  }
}

.horizontal-card__description {
  margin: 0;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  font-size: 15px;
  color: $color-text;
  line-height: 1.2;

  .additional-info {
    margin-top: 5px;
    color: $color-text;

    small {
      padding: 0 10px;
      position: relative;
      font-size: 14px;
      border-right: solid 1px;

      &:first-of-type {
        padding-left: 0;
      }

      &:last-of-type {
        border-right: none;
      }

      span {
        font-family: 'Source Sans Pro Semibold';
        color: $color-text--dark;
      }
    }
  }
}

.horizontal-card__content {
  padding: 20px;
  display: flex;
  flex: 1;

  > div {
    display: flex;
    flex-direction: column;

    &:first-of-type {
      padding-right: 20px;
      flex: 1;
    }

    &:last-of-type {
      padding-left: 20px;
      width: 150px;
      justify-content: space-between;
      border-left: solid 1px #e6e6e6;
    }
  }

  .btn {
    padding: 6px 0;
    font-size: 14px;
    text-transform: uppercase;
  }
}

.horizontal-card__content--mobile {
  display: none;
}


.horizontal-card__price {
  margin-bottom: 0;
  display: flex;
  flex-direction: column;
  font-size: 14px;
  color: $color-text;

  span {
    font-family: 'Source Sans Pro Semibold';
    font-size: 16px;
    color: $color-text--dark;
    line-height: 1;
  }
}

.horizontal-card--premium {
  .horizontal-card__title {
    &:hover,
    &:active,
    &:focus {
      color: $color-premium;
    }
  }
}

.horizontal-card--auction {
  overflow: hidden;

  .horizontal-card__title {
    color: $color-auction;
  }

  .horizontal-card__auction-date {
    margin-bottom: 0;
    display: inline-block;
    flex-direction: column;
    font-family: 'Source Sans Pro Semibold';
    font-size: 16px;
    color: $color-text--dark;
    line-height: 1.2;

    span {
      line-height: 1;
    }
  }
}

.horizontal-card--auction-highlighted {
  box-shadow: 0px 0px 0px 2px $color-auction;
}

@media (max-width: $screen-sm) {
  .horizontal-card {
    .btn {
      margin-top: 5px;
    }
  }

  .horizontal-card__image {
    width: 30%;
    min-width: 120px;
  }

  .horizontal-card__title {
    margin-bottom: 5px;
  }

  .horizontal-card__content {
    display: none;
  }

  .horizontal-card__price {
    flex-direction: row;
    align-items: center;
  }

  .horizontal-card__auction-date {
    white-space: nowrap;
    overflow: hidden;
  }

  .horizontal-card__content--mobile {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .btn {
      max-width: 140px;
    }
  }

  .horizontal-card--auction {
    .horizontal-card__title {
      color: $color-auction;
    }
  }
}
