table {
  width: 100%;
  color: $color-table;

  tbody {
    tr {
      border-top: solid 1px $color-border;

      &:last-of-type {
        border-bottom: solid 1px $color-border;
      }

      th {
        height: 50px;
        font-family: 'Source Sans Pro Semibold';
        font-size: 15px;
        color: $color-text--dark;
        vertical-align: middle;
      }

      td {
        font-size: 16px;

        &:last-of-type {
          text-align: right;
        }

        button {
          .icon {
            font-size: 17px;
            transition: color ease-in .1s;
          }

          &:hover,
          &:active,
          &:focus {
            .icon {
              color: $color-primary;
            }

            .icon--delete {
              color: $color-danger;
            }
          }
        }
      }
    }
  }
}

@media (max-width: $screen-sm-max) {
  table {
    display: none;
  }
}
