.confirmation-page {
  .inner-footer {
    justify-content: center;
  }
}

@media (max-width: $screen-sm) {
  .confirmation-page {
    padding: 0;

    .container {
      padding: 0;
    }
  }
}
