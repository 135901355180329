.cover {
  width: 100%;
  background: rgba(0, 0, 0, 0.6);
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 999;
}

.cover--global {
  background: rgba(0, 0, 0, 0.8);
  z-index: 999;
}
