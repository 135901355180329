.table-contacts {
  tbody {
    tr {
      &:nth-of-type(even) {
        background-color: #f9fafb;
      }

      &:first-of-type {
        &:hover {
          background-color: #fff;
          cursor: default;
        }
      }

      &:hover {
        background-color: #f1f8fe;
        cursor: pointer;

        .table-contacts__product {
          text-decoration: underline;
        }
      }

      th,
      td {
        padding: 14px 10px;
      }

      td {
        &:last-of-type {
          text-align: left;
        }
      }
    }
  }
}

.table-contacts__product-head {
  padding: 14px 10px 14px 30px !important;
  width: 370px;
}

.table-contacts__message-head {
  width: 250px;
}

.table-contacts__product {
  padding: 14px 0 14px 30px !important;
  color: $color-text--dark;

  > div {
    display: flex;
    align-items: center;
  }

  .image {
    margin-right: 20px;
    width: 85px;
    min-width: 85px;
    height: 60px;
    background-repeat: no-repeat !important;
    background-position: center !important;
    background-size: cover !important;
  }
}

.table-contacts__member {
  a {
    color: $color-text--dark;

    &:hover {
      color: $color-primary;
      text-decoration: underline;
    }
  }
}
