.search-banner {
  height: 230px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1 0 auto;
  font-family: "Source Sans Pro Semibold";
  font-size: 28px;
  color: #fff;
  line-height: 1.2;
  text-transform: uppercase;
  background: url(../../images/search-banner.jpg) no-repeat center;
  background-size: cover;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  h1 {
    margin-bottom: 0;
    font-size: 28px;
  }

  .searchbar {
    margin-top: 14px;
    width: 580px;

    .input-group-addon {
      height: 50px;
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
    }

    .form-control {
      height: 50px;
      font-family: "Source Sans Pro Semibold";
      font-size: 20px;
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
    }
  }
}

@media (max-width: $screen-sm-max) {
  .search-banner {
    opacity: 0;
    display: none;
    padding: 0 15px;
    height: 70px;
    background: $background-header;
    box-shadow: 0 2px 7px rgba(0, 0, 0, 0.1);
    animation-name: slideDown;
    animation-duration: 0.5s;
    animation-fill-mode: forwards;

    h1,
    span {
      display: none;
    }

    .searchbar {
      margin: 0 12px;
      width: 100%;

      .input-group-addon {
        height: 45px;
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
      }

      .form-control {
        height: 45px;
        font-size: 17px;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
      }
    }
  }
  .search-banner--expand {
    opacity: 1;
    display: flex;
    right: 0;
  }
  @keyframes slideDown {
    from {
      transform: translateY(-100%);
    }
    to {
      transform: translateY(0);
    }
  }
}
