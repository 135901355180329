.mobile-search {
  width: 68px;
  background-color: $background-header;
  margin-left: auto;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 10px;
  text-transform: uppercase;
  cursor: pointer;

  &:hover,
  &:active,
  &:focus {
    color: #fff;
  }

  .icon {
    font-size: 27px;
    color: #fff;
    padding-right: 10px;
  }

  &::after {
    content: "";
    position: absolute;
    right: -12px;
    top: 0;
    width: 0;
    height: 0;
    border-top: 70px solid $background-header--dark;
    border-right: 12px solid transparent;
  }
}

@media (min-width: $screen-md) {
  .mobile-search {
    display: none;
  }
}
