.title-block {
  padding: 29px 0 22px;
  display: flex;
  align-items: flex-end;

  .title {
    margin: 0 20px 0 0;
    display: flex;
    font-family: 'Source Sans Pro Semibold';
    font-size: 26px;
    color: $color-title;
  }

  .results {
    margin-left: auto;
    padding: 3px 15px;
    font-family: 'Source Sans Pro Regular';
    font-size: 15px;
    color: #183043;
    line-height: 1.4;
    border: solid 1px;
    border-radius: 50px;
    white-space: nowrap;
    align-self: center;
  }
}

.title-block__links {
  margin-left: auto;
  font-family: 'Source Sans Pro Regular';
  font-size: 15px;

  a {
    margin: 0 7px;
    color: $color-text;
    text-decoration: underline;

    &:first-of-type {
      margin-left: 0;
    }

    &:last-of-type {
      margin-right: 0;
    }
  }
}

@media (max-width: $screen-sm-max) {
  .title-block {
    flex-direction: column;
    align-items: flex-start;
    font-size: 24px;

    .title {
      align-self: center;
      text-align: center;
    }

    .results {
      margin: 15px 0 0 0;
    }
  }

  .title-block__links {
    margin-left: 0;
  }
}

@media (max-width: $screen-xs-max) {
  .title-block {
    .title {
      font-size: 20px;
      padding: 0;
      margin: 0;
      align-self: flex-start !important;
      text-align: left;
    }

    .results {
      display: none;
    }
  }

  .title-block__links {
    display: none;
  }
}
