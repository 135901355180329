.list-button {
  display: block;
  padding: 15px 25px;
  background-color: #fff;
  border-radius: 3px;
  box-shadow: 0 2px 7px rgba(0, 0, 0, .1);

  a {
    display: flex;
    align-items: center;

    &:hover,
    &:active,
    &:focus {
      text-decoration: none;

      .list-button__content {
        strong {
          color: $color-primary;
          text-decoration: underline;
        }
      }
    }
  }
}

.list-button__icon {
  margin-right: 20px;
  padding: 18px;
  display: inline-block;
  font-size: 30px;
  color: $color-primary;
  background-color: #efefef;
  border-radius: 50%;
}

.list-button__content {
  flex: 1;
  display: inline-block;

  strong {
    margin-bottom: 2px;
    display: flex;
    font-family: 'Source Sans Pro Semibold';
    font-size: 18px;
    color: $color-text--dark;
  }

  p {
    margin: 0;
    color: $color-text;
    line-height: 1.2;
  }
}

.list-button--loading {
  pointer-events: none;

  .spinner {
    width: 100%;

    &:before {
      margin: 0 auto;
    }
  }
}
