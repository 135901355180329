.list-page {
  section {
    display: flex;
  }

  .sticky-buttons {
    display: none;
  }
}

@media (max-width: $screen-sm-max) {
  .list-page {
    .sticky-buttons {
      display: flex;
    }

    & ~ footer {
      padding: 55px 0 85px;
    }
  }
}

@media (max-width: $screen-xs-max) {
  .list-page {
    flex-direction: column;

    main {
      .buttons {
        .list-button {
          width: 100%;

          &:first-of-type {
            display: none;
          }
        }
      }
    }
  }
  @keyframes slideDown {
    from {
      transform: translateY(-100%);
    }
    to {
      transform: translateY(0);
    }
  }
}
