.filter-block {
  margin: 0 0 20px;
  height: 45px;
  display: flex;
  justify-content: space-between;
  border-top: solid 1px $color-border;
  border-bottom: solid 1px $color-border;

  form {
    display: flex;
    align-items: center;

    label {
      margin: 0;
    }

    select {
      width: auto;
      border: none;
      background-color: $background;
    }
  }
}

.filter-block__links {
  display: flex;
  flex-direction: column !important;
  align-items: flex-end;
  position: relative;
  top: -10px;

  a {
    max-width: 550px;
    padding-left: 25px;
    display: inline-block;
    color: $color-text;
    text-decoration: underline;
    background-color: $background;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

@media (max-width: $screen-sm) {
  .filter-block__links {
    display: none;
  }
}
