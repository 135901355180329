.status {
  font-family: 'Source Sans Pro Semibold';
}

.status--online {
  color: #18ac65;
}

.status--offline {
  color: $color-danger;
}
