.category {
  display: flex;
  flex-direction: column;

  &:hover,
  &:active,
  &:focus {
    color: initial;
    text-decoration: none;

    .category__title {
      .title {
        color: $color-primary;
        text-decoration: underline;
      }
    }

    .category__image {
      &:before {
        opacity: 1;
        transition: opacity ease-in .1s;
      }
    }
  }
}

.category__title {
  height: 53px;
  width: 100%;
  margin-bottom: 10px;
  display: flex;
  align-items: flex-end;
  font-family: 'Source Sans Pro Regular';
  font-size: 15px;
  line-height: 1.1;
  color: $color-text--light;

  > span {
    display: inline-block;
    width: 100%;

    .title {
      font-size: 19px;
      color: #419de8;
      font-family: 'Source Sans Pro Semibold';
    }

    .quantity {
      line-height: 1.3;
    }
  }
}

.category__image {
  height: 150px;
  position: relative;
  display: block;
  border-radius: 5px;
  background-repeat: no-repeat !important;
  background-position: center !important;
  background-size: cover !important;
  box-shadow: 0 2px 7px rgba(0, 0, 0, .1);

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    border-radius: 5px;
    background: rgba(0, 0, 0, .11);
    opacity: 0;
  }
}

@media (max-width: $screen-sm-max) {
  .category__image {
    background-size: cover !important;
  }
}

@media (max-width: $screen-xs-max) {
  .category {
    .quantity {
      display: none;
    }
  }
}

