.premium-status {
  margin: 0 15px 0 auto;
  display: flex;
  align-items: center;
}

.premium-status__content {
  margin: 0 10px 0 0;
  text-align: right;

  strong {
    font-family: 'Source Sans Pro Semibold';
  }

  span {
    display: block;
  }
}

.premium-status__image {
  img {
    width: 35px;
  }
}
