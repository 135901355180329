.account-filter {
  padding: 20px 30px;

  h3 {
    margin-bottom: 0;
    font-family: 'Source Sans Pro Semibold';
    font-size: 15px;
    color: #001225;
    text-transform: uppercase;
  }

  .collapse-button {
    position: relative;
    display: flex;
    font-family: 'Source Sans Pro Semibold';
    font-size: 15px;
    color: #001225;
    text-transform: uppercase;

    &:after {
      position: absolute;
      top: 5px;
      right: 0;
      content: '\e91d';
      font-family: 'icomoon';
      font-size: 8px;
      color: $color-text--dark;
    }

    &:hover,
    &:active,
    &:focus {
      text-decoration: none;
    }

    &[aria-expanded="true"] {
      &:after {
        content: '\e91c';
      }
    }
  }

  .filter-search {
    width: 100%;
  }
}

.account-filter__inner {
  padding-top: 15px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.account-filter__section {
  padding: 0 20px;
  flex: 1;
  align-self: stretch;

  &:first-of-type {
    padding-left: 0;
  }

  &:last-of-type {
    padding-right: 0;
    border: none;
  }
}

@media (min-width: $screen-md) {
  .account-filter {
    .collapse-button {
      pointer-events: none;

      &:after {
        display: none;
      }
    }

    .collapse {
      display: block;
    }
  }
}

@media (max-width: $screen-sm-max) {
  .account-filter__inner {
    flex-direction: column;
    width: 100%;
  }

  .account-filter__section {
    width: 100%;
    margin-bottom: 20px;
    padding: 0 0 15px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border-bottom: solid 1px $color-border;

    &:last-of-type {
      margin-bottom: 0;
      padding: 0;
    }
  }
}
