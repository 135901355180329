.confirmation {
  text-align: center;
}

.confirmation__subtitle,
.confirmation__view,
.confirmation__post {
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  font-size: 15px;
}

.confirmation__subtitle {
  font-family: 'Source Sans Pro Semibold';
  color: $color-text--dark;

  span {
    display: block;
    color: $color-text;
    font-family: 'Source Sans Pro Regular';
  }
}

.confirmation__view {
  color: $color-text;

  a {
    display: block !important;
    color: $color-primary;
    text-decoration: underline;
  }
}

.confirmation__post {
  span {
    display: flex;
    align-self: center;
    font-family: 'Source Sans Pro Semibold';
    color: $color-text--dark;
  }

  .btn {
    margin: 10px 5px 0;
    font-family: 'Source Sans Pro Regular';
  }
}

@media (max-width: $screen-sm) {
  .confirmation__post {
    display: none;
  }
}
