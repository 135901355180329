.spinner {
  &:before {
    width: 15px;
    display: flex;
    font-family: 'icomoon';
    font-size: 15px;
    font-style: normal;
    content: "\e97b";
    animation: spin 1s infinite ease-in-out;
  }
}

.spinner--large {
  &:before {
    font-size: 35px;
    width: 35px;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
    transform-origin: 50%;
  }
  100% {
    transform: rotate(359deg);
  }
}
