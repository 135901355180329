.product-page {
  section {
    display: flex;

    main {
      flex: 1;
    }

    aside {
      margin-left: 20px;
      width: 350px;
    }
  }

  .btn--fixed {
    display: none;
  }

  .card {
    .btn {
      @extend .btn--primary;
    }

    .form-control {
      @extend .form-control--primary;
    }
  }
}

.product-page--premium {
  .card {
    .btn {
      @extend .btn--premium;
    }

    .form-control {
      @extend .form-control--premium;
    }
  }
}

.product-page--auction {
  .card {
    .btn {
      @extend .btn--auction;
    }

    .form-control {
      @extend .form-control--auction;
    }
  }
}

@media (max-width: $screen-sm-max) {
  .product-page {
    section {
      flex-direction: column;

      aside {
        margin-top: 20px;
        margin-left: 0;
        width: unset;
      }
    }
  }
}

@media (max-width: $screen-sm) {
  .product-page {
    .btn--fixed {
      display: flex;
    }
  }
}
