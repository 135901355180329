.watchlist {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: rgba(255, 255, 255, .8);

  &:hover,
  &:active,
  &:focus {
    color: rgba(255, 255, 255, 1);
    transition: color ease-in .1s;
  }

  .number {
    background: $background-header--dark;
    color: #fff;
    padding: 4px 10px;
    border-radius: 50px;
    margin-bottom: 2px;
    font-size: 12px;
  }
}
