.check-filter {
  padding-left: 0;
}

.check-filter:not(.check-filter--expand) {
  .check:nth-child(n+6) {
    display: none;
  }
}

.check-collapse {
  margin-top: 10px;
  padding: 0;
  border: none;
  color: $color-link;
  text-decoration: underline;
  text-align: left;

  &:after {
    content: '+ See more';
  }
}
.check-filter--expand ~ .check-collapse:after {
  content: '- See less';
}

.check-filter--expand {
  max-height: 270px;
  overflow-y: scroll;
}
