.link {
  color: $color-text;
  text-decoration: underline;
  transition: color ease-in .1s;

  &.success,
  &.error {
    text-decoration: none;

    .icon {
      margin-right: 3px;
    }
  }

  &.success {
    .icon {
      color: $color-success;
    }
  }

  &.error {
    .icon {
      color: $color-danger;
    }
  }
}

.link--primary {
  &:hover,
  &:active,
  &:focus {
    color: $color-primary;
  }
}

.link--premium {
  &:hover,
  &:active,
  &:focus {
    color: $color-premium;
  }
}

.link--auction {
  &:hover,
  &:active,
  &:focus {
    color: $color-auction;
  }
}

