.payment-page {
	form {
		img {
			display: none;
		}

		input[type="IMAGE"] {
			padding: 20px 30px;
			transition: background-color ease-in-out .2s;
			border: solid 1px #e4e4e4;
			border-radius: 5px;
			margin: 0 15px;

			&:hover {
				background-color: #f1f1f1;
			}

			&[name="PAYLIB"] {
				padding: 16px 30px;
			}
		}
	}
}
