.classified-ads-filters {
  justify-content: flex-start;
  flex-wrap: wrap;

  .btn {
    margin-left: auto;
    margin-top: 25px;
  }
}

.classified-ads-filters__section {
  width: 33%;
  flex: unset;
  border-right: solid 1px $color-border;

  &:last-of-type {
    border-right: none;
  }

  ul {
    width: 100%;
    padding: 0;
    margin: 0;
    list-style: none;
  }

  .filter-group {
    width: 100%;
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;

    label {
      width: 120px;
      min-width: 120px;
    }
  }
}

@media (max-width: $screen-md-max) {
  .classified-ads-filters__section {
    width: 100%;
  }
}

@media (max-width: $screen-sm-max) {
  .classified-ads-filters__section {
    border-right: none;

    .filter-select {
      &:nth-of-type(2),
      &:nth-of-type(3) {
        margin-left: 0;
      }
    }

    .filter-search {
      width: 100%;
    }
  }
}
