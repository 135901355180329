.modal {
  &.in {
    .modal-dialog {
      transform: none;
    }
  }

  .modal-open & {
    padding: 0 !important;
  }

  .close {
    opacity: 1;
    text-shadow: none;
  }

  .carousel-inner {
    border-radius: 0;
  }

  // For some reasons, the initially set "touch" value makes disappear the "Click to Contact the Seller" btn on iPhones.
  // Regarding the usefullness of this attribute, let's unset
  // @see https://developer.mozilla.org/fr/docs/Web/CSS/-webkit-overflow-scrolling
  -webkit-overflow-scrolling: unset;
}

.modal-dialog {
  margin: 20px auto 0;
  transform: none;
}

.modal-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: 'Source Sans Pro Semibold';
  padding: 0;
  padding-bottom: 20px;
  margin-bottom: 20px;
  font-size: 26px;
  font-weight: bold;

  .subtitle {
    margin-top: 10px;
    font-size: 18px;
    font-weight: 300;
  }

  .close {
    position: absolute;
    right: 20px;
    top: 20px;
    padding: 5px;
    font-size: 16px;
    color: #5b6a79;
  }
}

.modal__navigation {
  display: none;
}

.modal-content {
  padding: 30px 70px;
  border: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;

  .btn--fixed {
    display: none;
  }
}

.modal-body {
  padding: 0;
}

.modal-body--loading {
  height: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f8f9fa;
}

.modal__split-content {
  display: flex;

  .product {
    flex: 1;
    box-shadow: none;
  }

  aside {
    margin: 0 50px 0 0;
    width: 300px;
  }
}

.modal--full {
  .modal-dialog {
    margin: 0 auto;
  }

  .modal-header {
    flex-direction: row;
    font-size: 18px;
    min-height: 70px;
    color: #001225;
    margin: 0;
    margin-bottom: 15px;
    padding: 0;
    padding-left: 20px !important;
    background-color: #fff;
    border-bottom: 1px solid #e5e5e5;
    box-shadow: 0 2px 7px rgba(0, 0, 0, .1);

    .icon--filter {
      margin-right: 12px;
      font-size: 16px;
      color: $color-primary;
    }

    .close {
      color: #031c2f;
      position: static;
      margin-left: auto;
      width: 70px;
      height: 70px;
      border-left: solid 1px $color-border;
      font-size: 17px;
    }
  }

  .modal-dialog {
    margin: 0;
    width: 100%;
    position: fixed;
    top: 0;
    bottom: 0;
  }

  .modal-content {
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 0;
    border: none;
    border-radius: 0;
    box-shadow: none;
  }

  .modal-body {
    padding: 15px 15px 60px;
    overflow: auto !important;
  }

  .btn--fixed {
    display: flex;
  }
}

.modal--product,
.modal--auction,
.modal--premium {
  .modal-dialog {
    width: 900px;
    margin: 0 auto;
  }

  .overlay {
    margin-bottom: -30px;
  }

  .modal-content {
    padding: 0;
  }

  .close {
    position: absolute;
    top: 22px;
    right: 25px;
    z-index: 9;
    color: #787878;
    font-size: 17px;
  }
}

.modal--product {
  .modal-body {
    padding-bottom: 70px;
  }

  .btn {
    @extend .btn--primary;
  }

  .form-control {
    @extend .form-control--primary;
  }

  iframe {
    margin-top: 5px;
  }
}

.modal--auction {
  .btn {
    @extend .btn--auction;
  }

  .form-control {
    @extend .form-control--auction;
  }

  .modal-footer {
    button {
      background-color: $color-auction;
    }
  }
}

.modal--premium {
  .btn {
    @extend .btn--premium;
  }

  .form-control {
    @extend .form-control--premium;
  }

  .modal-footer {
    button {
      background-color: $color-premium;
    }
  }
}

.modal--account {
  .modal-dialog {
    width: 460px;
  }

  .modal-body {
    font-size: 15px;
  }
}

@media (max-width: $screen-sm-max) {
  .modal {
    .modal-dialog {
      width: 100%;
    }
  }

  .modal-body--loading {
    height: 100vh;
  }

  .modal--full,
  .modal--product,
  .modal--auction,
  .modal--premium {
    .modal-header {
      min-height: 50px;

      .close {
        width: 50px;
        height: 50px;
      }
    }
  }

  .modal-open {
    overflow: hidden;
  }
}

@media (max-width: $screen-xs-max) {
  .modal-content {
    .btn--fixed {
      display: flex;
    }
  }

  .modal-body--loading + .btn--fixed {
    display: none;
  }

  .modal--full {
    .modal-content {
      padding-bottom: 100px;
    }
  }

  .modal__split-content {
    flex-direction: column;

    aside {
      margin: 0 20px;
      width: unset;
    }
  }
}
