.list-group {
  width: 480px;
  max-width: 100%;

  .list-group-item {
    &:nth-of-type(even) {
      background-color: #fbfbfc;
    }

    .radio {
      margin: 0;
    }
  }
}

.list-group__previous {
  a {
    padding: 5px 15px;
    display: flex;
    align-items: center;
    font-family: 'Source Sans Pro Semibold';
    color: $color-text--dark;

    &:hover,
    &:active,
    &:focus {
      color: $color-text--dark;
      text-decoration: none;
    }

    .icon {
      margin-right: 15px;
      font-size: 12px;
      color: #9aa1a9;
    }
  }

  a:nth-child(2) {
    padding: 5px 45px;
  }

  & ~ .list-group-item {
    padding-left: 70px;
  }
}

.list-group--horizontal {

}
