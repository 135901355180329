.contact {
  margin: 30px 0 0;
  display: flex;
}

.contact__form {
  flex: 1;
}

.contact__address {
  margin-top: 25px;
  margin-left: 80px;
  flex: 1;

  p {
    margin-bottom: 5px;
  }
}

@media (max-width: $screen-sm-max) {
  .contact {
    flex-direction: column-reverse;
  }

  .contact__form {
    margin-top: 35px;
  }

  .contact__address {
    margin: 0;
  }
}
