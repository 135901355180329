.drop {
  font-size: 15px;
  color: $color-text;
}

.drop__title {
  margin: 0 0 20px;
  font-family: 'Source Sans Pro Semibold';
  color: $color-text--dark;
}

.drop__zone {
  display: flex;
  align-items: center;
  border: dashed 1px $color-border;
  background-color: #fbfbfc;
}

.drop__target {
  padding: 0 40px;
  height: 170px;
  display: flex;
  align-items: center;

  .input-file {
    opacity: 0;
    width: 100%;
    height: 170px;
    position: absolute;
    cursor: pointer;
  }

  .mobile {
    display: none;
  }

  .icon {
    margin-right: 40px;
    color: #c7c7c7;
    font-size: 60px;
  }

  > div {
    display: flex;
    flex-direction: column;
    align-items: center;

    .btn {
      margin-top: 15px;
    }
  }
}

.drop__help {
  padding: 0 40px;
  height: 120px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-left: solid 1px $color-border;

  p {
    margin-bottom: 5px;
    font-style: italic;
  }
}

@media (max-width: $screen-md) {
  .drop__target {
    width: 100%;
    height: 75px;
    justify-content: center;

    .desktop {
      display: none;
    }

    .mobile {
      display: flex;
    }

    .icon {
      display: none;
    }

    > div {
      > span {
        display: none;
      }

      .btn {
        margin-top: 0;
      }
    }
  }

  .drop__help {
    display: none;
  }
}

@media (max-width: $screen-sm) {
  .drop__title {
    text-align: center;
  }
}
