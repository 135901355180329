.payment {
  margin-left: auto;
  height: 45px;
  display: flex;
  align-items: center;
  font-size: 15px;
  color: $color-text;

  span,
  img {
    padding: 0 11px;
    height: 20px;
  }

  .mastercard {
    height: 28px;
  }
}

@media (max-width: $screen-sm) {
  .payment {
    height: unset;
    width: 100%;
    justify-content: center;
    flex-wrap: wrap;

    span {
      margin-bottom: 15px;
      width: 100%;
      text-align: center;
    }
  }
}
