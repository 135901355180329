// Bootstrap breakpoints
$screen-xs: 480px;
$screen-sm: 768px;
$screen-md: 992px;
$screen-lg: 1200px;
$screen-xs-max: ($screen-sm - 1);
$screen-sm-max: ($screen-md - 1);
$screen-md-max: ($screen-lg - 1);

// Bootstrap colors
$input-border: #e7e7e7;

// Colors
$color-highlight: #4ba6ef;
$color-premium: #f4c23b;
$color-primary: #4ba6ef;
$color-primary-disabled: #8ab9de;
$color-primary--light: #edf6fd;
$color-success: #18ac65;
$color-danger: #d9534f;
$color-auction: #f46f3b;

$color-border: #d9d9d9;
$color-header: #9fabb6;
$color-form: #6f7a86;
$color-link: #419de8;
$color-text--light: #8999a9;
$color-text: #5b6a79;
$color-text--dark: #001226;
$color-title: #001225;
$color-table: #6f7a86;
$color-table-row: #f9fafb;
$color-step: #6f7a86;

$background: #f8f9fa;
$background-header: #4ba6ef;
$background-header--dark: #183043;
