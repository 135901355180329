.invoice {}

.invoice__logo {
  margin: 37.5pt 0 75pt;

  img {
    width: 150pt;
  }
}

.invoice__address {
  margin-bottom: 75pt;
}

.invoice__info {
  margin-bottom: 22.5pt;
  text-align: right;

  p {
    margin-right: 22.5pt;
    display: inline-block;

    &:last-of-type {
      margin-right: 0;
    }
  }
}

.invoice__content {
  margin-bottom: 75pt;

  table {
    display: table !important;

    th {
      &:last-of-type {
        text-align: right;
      }
    }

    td {
      padding: 3.75pt 0;
    }

    .total {
      font-family: 'Source Sans Pro Semibold';
      color: $color-text--dark;
      border-bottom: none;

      td {
        text-align: right;
      }
    }
  }
}

.invoice__legals {
  margin-bottom: 75pt;
  font-size: 10.5pt;
  text-align: center;

  p {
    margin: 0;
  }
}
