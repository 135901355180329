.product {
  overflow: hidden;
  background-color: #fff;
  box-shadow: 0 2px 7px rgba(0, 0, 0, .1);
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.product__content {
  padding: 35px 50px;
  font-size: 16px;
  position: relative;
  color: #5b6a79;

  h1 {
    margin: 0;
    font-family: 'Source Sans Pro Semibold';
    font-size: 20px;
    color: #031c2f;
    line-height: 1.2;
  }

  .info {
    padding: 25px 0;
    margin: 25px 0;
    font-size: 15px;
    border-top: solid 1px #d9d9d9;
    border-bottom: solid 1px #d9d9d9;

    > div {
      margin-bottom: 10px;
      display: flex;

      span {
        color: #031c2f;

        &:first-of-type {
          width: 130px;
          min-width: 130px;
          font-family: 'Source Sans Pro Semibold';
          color: #5b6a79;
        }
      }
    }
  }
}

.product__similars {
  .modal & {
    padding: 35px 50px;

    .title {
      margin: 0;
      font-family: 'Source Sans Pro Semibold';
      font-size: 20px;
      color: #031c2f;
      line-height: 1.2;
    }
  }
}

@media (max-width: $screen-sm) {
  .product__content {
    padding: 35px 20px;
  }
}
