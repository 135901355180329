.breadcrumb {
  margin-bottom: 0;
  padding: 28px 0 18px;
  background-color: transparent;
  border-radius: 0;
}

.breadcrumb__item {
  margin-right: 10px;
  padding-right: 10px;
  position: relative;

  &:before {
    padding: 0 !important;
    position: absolute;
    top: 5px;
    right: -3px;
    content: '\e90f' !important;
    font-family: 'icomoon';
    font-size: 8px;
    color: $color-text !important;
  }

  &:first-of-type {
    padding-left: 0;
  }

  &:last-of-type {
    &:before {
      content: '' !important;
    }

    a {
      color: #00081b;
      pointer-events: none;
    }
  }

  a {
    color: $color-text;
  }
}

.breadcrumb--short {
  .breadcrumb__item {
    padding-left: 15px;
    color: $color-text;

    &:before {
      position: absolute;
      top: 4px;
      left: 0;
      content: '\e902' !important;
      font-family: 'icomoon';
      font-size: 8px;
      color: $color-text !important;
    }
  }
}

@media (max-width: $screen-sm) {
  .breadcrumb {
    display: none;
  }

  .breadcrumb--short {
    padding: 20px 0;
    display: flex;
  }
}
