.card {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 2px 7px rgba(0, 0, 0, .1);
  cursor: pointer;
  overflow: hidden;

  &:hover,
  &:active,
  &:focus {
    text-decoration: none;
  }

  .image-count {
    position: absolute;
    bottom: 8px;
    right: 8px;
  }
}

.card__title {
  margin-bottom: 10px;
  display: inline-block;
  font-family: 'Source Sans Pro Semibold';
  font-size: 20px;
  color: $color-title;
  line-height: 1;
  max-width: 100%;
}

.card__image {
  height: 150px;
  position: relative;
  display: block;
  background-repeat: no-repeat !important;
  background-position: center !important;
  background-size: cover !important;
  box-shadow: 0 2px 7px rgba(0, 0, 0, .1);
}

.card__content {
  padding: 17px 25px 25px;
  display: flex;
  flex-direction: column;
}

.card__description {
  margin-bottom: 10px;
  font-size: 15px;
  color: $color-text;
}

.card__location {
  font-size: 14px;
  color: $color-text;

  span {
    font-family: 'Source Sans Pro Semibold';
    color: $color-text--dark;
  }
}

.card--highlight {
  &:hover,
  &:active,
  &:focus {
    color: initial;
    box-shadow: 0px 0px 0px 2px $color-auction;

    .card__title {
      color: $color-auction;
      text-decoration: underline;
    }
  }
}

@media (max-width: $screen-sm-max) {
  .card__image {
    background-size: cover !important;
  }
}
