.premium {
  margin-top: 55px;
  height: 410px;
  position: relative;
  display: flex;
  align-items: center;
  z-index: 0;

  .container {
    height: 100%;
    position: relative;
    display: flex;
    align-items: center;

    > img {
      width: 580px;
      position: absolute;
      top: 0;
      left: -140px;
    }
  }
}

.premium__content {
  margin-left: 480px;
  padding: 30px 0;
  height: 100%;
  position: relative;
  top: -20px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  z-index: 1;
  background-color: $background;

  .title {
    margin-bottom: 20px;
    display: flex;

    img {
      margin-right: 15px;
      width: 60px;
    }

    h2 {
      margin: 0;
      display: flex;
      flex-direction: column;
      font-family: 'Source Sans Pro Semibold';
    }

    span {
      display: block !important;
    }
  }

  ul {
    margin-bottom: 30px;
    padding: 0;
    color: $color-text;
    list-style: none;

    li {
      position: relative;
      padding-left: 27px;

      &:before {
        content: '';
        width: 5px;
        height: 5px;
        border-radius: 50%;
        background-color: $color-text--dark;
        position: absolute;
        top: 8px;
        left: 7px;
      }
    }
  }

  .btn {
    margin-left: 7px;
    align-self: flex-start;
  }
}

@media (max-width: $screen-sm-max) {
   .premium {
    display: none;
  }
}
