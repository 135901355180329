.account-subnav {
  margin: 0;
  padding: 0;
  display: flex;
  list-style: none;
}

.account-subnav__item {
  a {
    margin: 0 15px;
    padding: 0 0 20px;
    font-family: 'Source Sans Pro Semibold';
    font-size: 19px;
    color: $color-text--light;
    align-self: flex-end;
    border-bottom: solid 2px transparent;

    &:hover,
    &:focus,
    &:active {
      color: $color-text--dark;
      text-decoration: none;
      transition: color ease-in .1s;
    }
  }
}

.account-subnav__item--active {
  a {
    color: $color-text--dark;
    border-bottom: solid 2px $color-text--dark;
  }
}


@media (max-width: $screen-md) {
  .account-subnav__item {
    display: none;
  }

  .account-subnav__item--active {
    display: block;

    a {
      margin: 0;
      font-size: 18px;
      border-bottom: none;
    }
  }
}
