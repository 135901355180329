.email-alert {}

.email-alert__inner {
  padding: 30px;
  position: relative;
  top: -35px;
  border-radius: 5px;
  box-shadow: 0 3px 35px rgba(0, 0, 0, .1);
  background-color: #fff;
}

.email-alert__title {
  margin-top: 0;
  margin-bottom: 5px;
  display: flex;
  justify-content: center;
  font-family: 'Source Sans Pro Semibold';
  font-size: 26px;

  .icon {
    margin-right: 15px;
    color: $color-highlight;
  }
}

.email-alert__subtitle {
  margin-bottom: 10px;
  font-family: 'Source Sans Pro Semibold';
  font-size: 18px;
  color: $color-text;
  text-align: center;
}

.email-alert__content {
  height: 70px;
  display: flex;
  justify-content: center;

  form {
    padding-right: 40px;
    width: 66%;
    display: flex;
    align-items: center;

    .form-group {
      margin-bottom: 0;
      padding: 0 7px;
      flex: 1;

      select,
      input {
        width: 100%;
      }
    }

    .btn {
      margin-left: 7px;
    }
  }

  .form-help {
    padding-left: 40px;
    position: relative;
    display: flex;
    align-items: center;
    color: #6f7a86;
    font-size: 15px;
    border-left: solid 1px #cfd1d5;

    > div {
      width: 35px;
      height: 35px;
      position: absolute;
      top: 50%;
      left: -19px;
      display: flex;
      align-items: center;
      color: #cfd1d5;
      border: solid 1px #cfd1d5;
      border-radius: 50%;
      background-color: #fff;
      transform: translateY(-50%);

      span {
        position: relative;
        left: 8px;
        color: #6f7a86;
      }
    }

    > p {
      a {
        color: $color-highlight;
        text-decoration: underline;
      }

      span {
        display: flex;
      }
    }
  }
}

@media (max-width: $screen-sm-max) {
  .email-alert {
    padding: 0;
    border-radius: 0;
  }

  .email-alert__inner {
    top: 0;
  }

  .email-alert__title {
    margin-bottom: 0;
    font-size: 24px;
    flex-direction: column;
    align-items: center;

    .icon {
      margin-right: 0;
      margin-bottom: 10px;
    }
  }

  .email-alert__subtitle {
    margin-bottom: 25px;
    font-size: 18px;
  }

  .email-alert__content {
    height: unset;
    flex-direction: column;

    form {
      padding-right: 0;
      width: 100%;
      flex-direction: column;

      .form-group {
        width: 100%;
        margin-bottom: 20px;
      }
    }

    .form-help {
      margin-top: 55px;
      padding-left: 0;
      padding-top: 55px;
      border-top: solid 1px #cfd1d5;
      border-left: none;
      justify-content: center;

      > div {
        top: -18px;
        left: 50%;
        transform: translateX(-50%);

        span {
          left: 9px;
        }
      }
    }
  }
}
