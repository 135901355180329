.dropdown {
  .dropdown-toggle {
    &:hover,
    &:active,
    &:focus {
      color: #fff;
      transition: ease-in .1s;
    }
  }

  .dropdown-menu {
    padding: 30px;
    top: 35px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    border: none;
    background-color: #0f212f;
  }

  .dropdown-item {
    font-size: 17px;
    margin-bottom: 6px;

    &:hover,
    &:active,
    &:focus {
      color: #fff;
      transition: ease-in .1s;
    }
  }
}

.dropdown.open {
  .dropdown-toggle {
    position: relative;
    color: #fff;
    transition: ease-in .1s;

    &:after {
      content: '';
      width: 100%;
      position: absolute;
      bottom: -15px;
      height: 2px;
      background: white;
      left: 0;
    }
  }
}
