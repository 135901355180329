.table-invoices {
  tbody {
    tr {
      th,
      td {
        padding: 15px 30px;
      }

      &:nth-of-type(even) {
        td {
          background-color: #f9fafb;
        }
      }
    }
  }
}
