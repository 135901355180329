.radio {
  margin: 0;

  input {
    display: none;
  }

  label {
    margin-bottom: 0;
    padding-left: 30px;
    width: unset;
    display: flex;
    align-items: center;
    line-height: 1;

    &:before {
      padding: 0;
      width: 20px;
      height: 20px;
      min-height: unset;
      min-width: unset;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      display: flex;
      border-radius: 50px;
      background-color: #f9f9f9;
      border: solid 1px $color-border;
      content: '';
    }
  }

  input:checked + label:after {
    width: 10px;
    height: 10px;
    position: absolute;
    top: 50%;
    left: 5px;
    transform: translateY(-50%);
    display: flex;
    background-color: $color-primary;
    border-radius: 50px;
    content: '';
  }
}

.radio + .radio {
  margin: 0;
}
