.table-invoices-mobile {
  display: flex;
  flex-direction: column;
}

.table-invoices-mobile__item {
  padding: 20px;
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  color: $color-text;
  border-bottom: solid 1px $color-border;

  &:nth-of-type(even) {
    background-color: #f9fafb;
  }
}

.table-invoices-mobile__info {
  display: flex;
  flex-direction: column;

  p {
    margin: 0 0 5px;
  }

  .amount {
    font-family: 'Source Sans Pro Semibold';
    color: $color-text--dark;
  }
}

.table-invoices-mobile__button {
  font-size: 18px;
}

@media (min-width:  $screen-md) {
  .table-invoices-mobile {
    display: none;
  }
}
