.product-contact {
  margin-bottom: 20px;
  padding: 0 0 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 2px 7px rgba(0, 0, 0, .1);
  position: relative;

  > div {
    width: 100%;
  }

  form {
    padding: 10px 35px 0;
    width: 100%;
  }

  textarea {
    height: 100px;
  }

  .checkbox {
    margin-bottom: 25px;
  }

  .premium-image {
    position: absolute;
    top: -3px;
    right: 15px;
    width: 35px;
  }
}

.product-contact__title {
  margin: 0 0 20px;
  padding: 30px 20px 25px;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: 'Source Sans Pro Semibold';
  font-size: 20px;
  color: #fff;
  line-height: 1;
  text-align: center;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #031c2f;

  > p {
      margin: 0 0 7px;

    span {
      color: $color-primary;
    }
  }

  img {
    width: 30px;
    position: absolute;
    top: -5px;
    right: 25px;
  }
}

.product-contact__login {
  padding: 10px 35px 0;
  text-align: center;

  .btn {
    max-width: 230px;
    margin: 0 auto 13px;

    &.btn--fixed {
      max-width: unset;
      margin-bottom: 0;
    }
  }

  hr {
    margin: 15px 0;
  }

  .create-account {
    display: flex;
    flex-direction: column;
    font-family: 'Source Sans Pro Semibold';
    font-size: 19px;

    span {
      line-height: 1;
    }
  }
}

.product-contact--premium {
  .product-contact__title {
    > p {
      span {
        color: $color-premium;
      }
    }
  }
}

.product-contact--auction {
  .product-contact__title {
    > p {
      span {
        color: $color-auction;
      }
    }
  }
}

.product-contact--light {
  .product-contact__title {
    padding: 25px 20px 0;
    color: #031c2f;
    font-size: 18px;
    background-color: transparent;
  }
}

@media (max-width: $screen-xs-max) {
  .product-contact {
    display: none;
  }

  .product-contact--light {
    display: flex;
  }
}
