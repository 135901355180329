.filter {
  padding: 20px;
  display: flex;
  flex-direction: column;
  border-bottom: solid 1px #e6e6e6;

  &:last-of-type {
    border-bottom: none;
  }

  a {
    color: $color-text;
  }

  .filter-search {
    margin: 5px 0;
  }

  .filter-search--sticked {
    margin: 5px 0 20px;
  }
}

.filter__title {
  margin: 0 0 10px;
  padding: 0;
  font-family: 'Source Sans Pro Semibold';
  font-size: 15px;
  text-align: left;
  color: $color-title;
  text-transform: uppercase;
  background: transparent;
  border: none;

  &:hover,
  &:active,
  &:focus {
    outline: none;
    box-shadow: none;
  }
}

.filter__go-back {
  margin-bottom: 7px;
  padding-left: 20px;
  position: relative;

  &:before {
    margin-right: 10px;
    position: absolute;
    left: 0;
    top: 3px;
    font-family: 'icomoon';
    font-size: 10px;
    content: '\e902';
  }
}

.filter__list {
  margin-bottom: 0;
  padding-left: 17px;

  li {
    margin-bottom: 5px;

    &.active {
      a {
        font-family: 'Source Sans Pro Semibold';
        color: $color-text--dark;
        pointer-events: none;
      }
    }
  }
}

.filter--collapsible {
  border-bottom: none;

  .filter__title {
    position: relative;
    border-bottom: solid 1px $color-border;
    padding-bottom: 10px;

    &:after {
      content: '\e91d';
      position: absolute;
      top: 6px;
      right: 0;
      font-family: 'icomoon';
      font-size: 8px;
      transform: rotate(0);
      transition: transform ease-in-out .2s;
    }

    &[aria-expanded="true"] {
      &:after {
        content: '\e91c';
      }
    }
  }

  .collapse.in {
    display: flex;
    flex-direction: column;
  }

  .collapse,
  .collapsing {
    > div {
      display: flex;
      flex-direction: column;
    }
  }
}
