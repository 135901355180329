header {
  height: 140px !important;
  max-height: 140px;
  display: flex;
  flex: 1 0 auto;
  position: relative;
  font-size: 17px;
  box-shadow: 0 3px 7px rgba(0, 0, 0, .11);
  z-index: 9;

  a {
    &:hover,
    &:active,
    &:focus { text-decoration: none; }
  }
}

.header__logo {
  height: 100%;
  width: 325px;
  position: relative;
  display: flex;
  align-items: center;
  background: #fff;

  > div {
    width: 100%;
  }


  img {
    padding: 0 35px;
    width: 100%;
  }


  &:before {
    content: '';
    position: absolute;
    right: -35px;
    top: 0;
    width: 0;
    height: 0;
    border-top: 140px solid #fff;
    border-right: 35px solid transparent;
  }
}

.header__content {
  background-color: $background-header;
  flex: 1;
  display: flex;
  flex-direction: column;

  .top {
    display: flex;
    flex: 1;

    form {
      padding: 0 30px 0 50px;
      display: flex;
      align-items: center;
      flex: 1;
    }

    a {
      padding: 0 28px;
      align-items: center;
      display: flex;
    }

    .watchlist {
      flex-direction: column;
      justify-content: center;

      .number {
        background: $background-header--dark;
        color: #fff;
        padding: 4px 10px;
        border-radius: 50px;
        margin-bottom: 2px;
        font-size: 12px;
      }
    }
  }

  .bottom {
    padding-left: 35px;
    height: 50px;
    background-color: $background-header--dark;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: $background-header;

    a {
      color: $color-header;
    }
  }
}

@media (max-width: $screen-sm-max) {
  header {
    height: 70px !important;
    max-height: 70px;
  }

  .header__logo {
    &:before {
      display: none;
    }

    img {
      padding: 0 25px 0 15px;
      width: 70%;
    }
  }

  .header__content {
    display: none;
  }
}
