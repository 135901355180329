.list-bloc {
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  list-style: none;
}

.list-bloc__item {
  margin-bottom: 25px;
  padding: 0 7px;
  width: 20%;
}

.list-bloc--highlight {
  .list-bloc--highlight__item {
    width: 25%;
  }
}

@media (max-width: $screen-sm-max) {
  .list-bloc {
    .list-bloc__item,
    .list-bloc--highlight__item {
      width: 50%;
    }
  }
}

@media (max-width: $screen-xs-max) {
  .list-bloc {
    margin-bottom: 25px;
  }
}
