.pagination {
  margin: 35px 0 0;
  display: flex;
  justify-content: center;

  ul {
    margin: 0;
    padding: 0;
    display: flex;
    list-style: none;
  }
}

.pagination__item,
.pagination__navigation {
  a {
    width: 35px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Source Sans Pro Semibold';
    font-size: 16px;
    color: $color-text;

    &:hover,
    &:active,
    &:focus {
      color: $color-text--dark;
      text-decoration: none;
      transition: color ease-in .1s;
    }

    .icon {
      font-size: 11px;
    }
  }
}

.pagination__item {
  &.pagination__item--active {
    a {
      color: $color-text--dark;
      border: solid 1px;
      border-radius: 50px;
      cursor: default;
    }
  }
}

.pagination__item--ellipsis {
  pointer-events: none;
}

.pagination__navigation {
  a {
    border: solid 1px;
    border-radius: 50px;
  }

  .icon {
    position: relative;
    display: flex;

    &.icon--previous {
      right: 1px;
    }

    &.icon--next {
      left: 1px;
    }
  }

  &.disabled {
    opacity: .3;

    a {
      cursor: default;
      pointer-events: none;
    }
  }
}

.pagination__navigation--previous {
  margin-right: 15px;
}

.pagination__navigation--next {
  margin-left: 15px;
}

@media (max-width: $screen-sm) {
  .pagination__item,
  .pagination__navigation {
    a {
      width: 30px;
      height: 30px;
    }
  }
}
