.account-nav {
  display: flex;

  ul {
    margin: 0;
    padding: 0;
    display: flex;
    flex: 1;
    list-style: none;
  }
}

.account-nav__item {
  flex: 1;

  a {
    margin-right: 0;
    height: 90px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: $color-header;
    text-transform: uppercase;
    text-align: center;
    background-color: $background-header--dark;
    border-right: solid 1px rgba(227, 183, 193, .2);

    .icon {
      margin-bottom: 10px;
      font-size: 20px;
    }

    .icon--contacts {
      font-size: 25px;
    }

    &:hover,
    &:active,
    &:focus {
      color: #fff;
      text-decoration: none;
      background-color: $color-primary;
    }
  }

  &.active {
    a {
      color: #fff;
      background-color: $color-primary;
    }
  }
}

@media (max-width: $screen-sm-max) {
  .account-nav {
    display: none;
  }
}
