.list {
  padding-left: 17px;

  li {
    list-style-type: disc;

    ul {
      margin: 5px 0 10px;
      padding-left: 48px;
    }
  }
}

.list--check {
  padding: 0;

  > li {
    list-style: none;
  }

  .icon {
    margin-right: 10px;
    color: $color-success;
  }
}
