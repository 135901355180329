.table-contacts-mobile {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
}

.table-contacts-mobile__row {
  height: 185px;
  padding: 15px;
  position: relative;
  display: flex;
  flex: 1;
  font-size: 16px;
  color: $color-table;
  border-top: solid 1px $color-border;

  &:hover,
  &:active,
  &:focus {
    color: $color-text;
    text-decoration: none;
  }

  &:last-of-type {
    border-bottom: solid 1px $color-border;
  }

  &:nth-of-type(odd) {
    background-color: #f9fafb;
  }
}

.table-contacts-mobile__image {
  width: 120px;
  height: 100%;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
  margin-right: 20px;
}

.table-contacts-mobile__content {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .header {
    margin-bottom: 11px;
    display: flex;

    .title {
      font-family: 'Source Sans Pro Semibold';
      font-size: 18px;
      color: #001226;
      line-height: 1.1;
    }

    button {
      margin-left: auto;
      padding: 0 0 0 20px;
      align-self: flex-start;
      font-size: 20px;
      color: #6f7a86;
    }
  }

  .message {
    margin-bottom: auto;
    line-height: 1.1;
  }

  .footer {
    display: flex;
    flex-direction: column;
  }
}

@media (min-width:  $screen-md) {
  .table-contacts-mobile {
    display: none;
  }
}

@media (max-width: $screen-xs-max) {
  .table-contacts-mobile__row {
    font-size: 15px;
  }

  .table-contacts-mobile__image {
    width: 100px;
  }
}
