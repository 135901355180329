.account-page {
  .shadow-container__footer {
    display: flex;
    flex-direction: column;

    hr {
      margin: 20px 0;
    }

    .btn {
      margin-left: auto;
    }
  }
}

@media (max-width: $screen-sm-max) {
  .account-page {
    padding-bottom: 0;

    .container {
      padding: 0;
      width: unset;
    }

    .breadcrumb {
      display: none;
    }

    .shadow-container__footer {
      .btn {
        margin: 0 auto;
      }
    }
  }
}
