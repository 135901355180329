.check {
  margin-bottom: 7px;

  &.active {
    label {
      color: $color-text--dark;
      font-family: 'Source Sans Pro Semibold';
    }
  }

  input {
    display: none;
  }

  label {
    margin: 0;
    padding-left: 30px;
    display: flex;
    font-size: 14px;
    color: $color-text;
    cursor: pointer;
    position: relative;

    &:before {
      content: '';
      width: 20px;
      height: 20px;
      position: absolute;
      left: 0;
      background-color: #fff;
      border: solid 1px #d3d3d3;
      border-radius: 4px;
    }
  }

  input:checked + label {
    &:before {
      background-color: $color-primary;
      border-color: $color-primary;
    }

    &:after {
      position: absolute;
      top: 3px;
      left: 5px;
      font-family: 'icomoon';
      font-size: 11px;
      content: '\e904';
      color: #fff;
    }
  }

  input[disabled] + label {
    cursor: not-allowed;

    &:before {
      background-color: #f5f5f5;
    }
  }

  input[disabled]:checked + label {
    &:before {
      background-color: $color-primary-disabled;
      border-color: $color-primary-disabled;
    }
  }
}

.check--minus {
  label {
    &:before {
      background-color: $color-primary;
      border-color: $color-primary;
    }

    &:after {
      position: absolute;
      top: 3px;
      left: 5px;
      font-family: 'icomoon';
      font-size: 10px;
      content: '\ea0b';
      color: #fff;
    }
  }
}

.check--small {
  margin-bottom: 3px;
  padding-left: 0;

  label {
    &:before {
      width: 16px;
      height: 16px;
      top: 2px !important;
    }
  }

  input:checked + label {
    &:after {
      top: 5px;
      left: 4px;
      font-size: 9px;
    }
  }
}

@media (max-width: $screen-sm-max) {
  .check--small {
    margin-bottom: 7px;
  }
}
