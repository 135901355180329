.category-select {
  margin-bottom: 20px;
  height: 100px;
  width: 480px;
  max-width: 100%;
  flex-direction: row;
  display: flex;
  border: solid 1px $color-border;
  border-radius: 5px;
}

.category-select__icon {
  width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-right: solid 1px $color-border;

  .icon {
    font-size: 50px;
    color: #9aa1a9;
  }
}

.category-select__list {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
  list-style: none;
}

.category-select__item {
  padding-left: 20px;
  display: flex;
  flex: 1;
  border-bottom: solid 1px $color-border;

  &:last-of-type {
    border-bottom: none;
  }

  .radio {
    margin: 0;
    display: flex;
    align-items: center;
    flex: 1;
  }
}

@media (max-width: $screen-sm) {
  .category-select__icon {
    width: 70px;

    .icon {
      font-size: 35px;
    }
  }
}
