.sidebar {
  margin-right: 30px;
  width: 240px;
  min-width: 240px;
  align-self: flex-start;
  background-color: #fff;
  box-shadow: 0 2px 7px rgba(0, 0, 0, .1);
  border-radius: 5px;

  form {
    display: flex;
    flex-direction: column;
  }
}

@media (max-width: $screen-sm-max) {
  .sidebar {
    display: none;
  }
}
