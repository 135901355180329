@font-face {
  font-family: 'Source Sans Pro Regular';
  src: url('../../fonts/sourcesanspro-regular.woff2') format('woff2'),
       url('../../fonts/sourcesanspro-regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Source Sans Pro Semibold';
  src: url('../../fonts/sourcesanspro-semibold.woff2') format('woff2'),
       url('../../fonts/sourcesanspro-semibold.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
