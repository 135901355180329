.filter-search {
  margin: 5px 0 20px;
  display: flex;
  flex-direction: row !important;
  height: 34px;

  input {
    margin-right: 10px;
    min-width: 0;
    flex: 1;
  }

  button {
    padding: 0 12px;
    display: flex;
    align-items: center;
    font-size: 16px;
    color: #fff;
    background: $color-primary;
    border-color: $color-primary;
    border-radius: 4px;

    &:hover,
    &:active,
    &:focus {
      outline: none;
      box-shadow: none;
    }
  }
}

.filter-search--sticked {
  flex-wrap: nowrap !important;

  input {
    margin-right: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right: none;
  }

  button {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}
