@font-face {
  font-family: 'icomoon';
  font-style: normal;
  font-weight: normal;
  src: url("../../fonts/icomoon.woff") format("woff");
}

.icon {
  font-family: 'icomoon' !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  line-height: 1;
  text-transform: none;
  speak: none;
}

$icons: (
  account: "\e900",
  ad: "\e901",
  auction: "\e919",
  back: "\e902",
  blast: "\e903",
  check: "\e904",
  clear: "\e905",
  close: "\e905",
  contacts: "\e906",
  delete: "\e907",
  download: "\e908",
  edit: "\e909",
  email: "\e90a",
  facebook: "\e90b",
  filter: "\e90c",
  invoice: "\e90d",
  job: "\e91b",
  machine: "\e91a",
  menu: "\e90e",
  next: "\e90f",
  pdf: "\e910",
  photo: "\e911",
  premium: "\e912",
  previous: "\e913",
  search: "\e914",
  triangle-down: "\e91d",
  triangle-up: "\e91c",
  twitter: "\e915",
  upload: "\e916",
  video: "\e917",
  youtube: "\e918",
  spinner: "\e97b",
  plus: "\ea0a",
  minus: "\ea0b"
);

@each $icon-name, $icon-sign in $icons {
  .icon--#{$icon-name}:before {
    content: $icon-sign;
  }
}
