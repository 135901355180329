.searchbar {
  .input-group {
    flex: 1;
  }

  .input-group-addon {
    padding: 0 5px 0 17px;
    border: none;
    font-size: 22px;
    color: $background-header;
    background-color: #fff;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }

  .form-control {
    padding: 24px 12px;
    color: $color-form;
    font-size: 19px;
    border: none;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    box-shadow: none;

    &::placeholder {
      color: $color-form;
      opacity: .7;
    }

    &:focus {
      &::placeholder {
        opacity: .5;
        transition: opacity ease-in .1s;
      }
    }
  }
}
