.photo-list {
  display: flex;
  flex-wrap: wrap;
}

.photo-list__title {
  margin: 0 0 20px;
  width: 100%;
  display: flex;
  color: $color-text;
}

.photo-list__upload-limit-error {
  color: red;
  width: 100%;
  display: flex;
}

.photo-list__item {
  margin-right: 20px;
  margin-bottom: 20px;
  width: 12%;
  position: relative;
  display: flex;
  flex-direction: column;
  font-size: 15px;
  line-height: 1;
  color: $color-text;

  &:last-of-type {
    margin-right: 0;
  }

  .image {
    margin-bottom: 10px;
    height: 80px;
    width: 100%;
    background-repeat: no-repeat !important;
    background-position: center !important;
    background-size: cover !important;
  }

  .image--removed {
    border: 2px solid $color-danger;
    opacity: .3;
  }

  .image-name {
    overflow: hidden;
  }

  button {
    height: 35px;
    width: 30px;
    position: absolute;
    top: 0;
    right: 0;
    color: #fff;
    background-color: rgba(0, 0, 0, .7);
    z-index: 3;

    &:hover,
    &:active,
    &:focus {
      background-color: $color-danger;
      transition: background-color ease-in .1s;
    }
  }
}

.photo-list__item--video {
  &:before {
    position: absolute;
    top: 20px;
    left: 50%;
    transform: translateX(-50%);
    font-family: 'icomoon';
    font-size: 30px;
    color: #fff;
    content: '\e917';
    z-index: 2;
  }

  &:after {
    width: 100%;
    height: 80px;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, .2);
    content: '';
    z-index: 1;
  }
}

.photo-list__item--pdf {
  .image {
    background: url('../../images/file.png');
    background-repeat: no-repeat !important;
    background-position: center !important;
    background-size: cover !important;
    border: solid 1px $color-border;
  }
}

@media (max-width: $screen-md) {
  .photo-list {
    justify-content: space-between;
  }

  .photo-list__item {
    margin-right: 0;
    width: 30%;

    .image {
      height: 110px;
    }
  }
}

@media (max-width: $screen-sm) {
  .photo-list__title {
    justify-content: center;
    text-align: center;
  }

  .photo-list__item {
    width: 45%;
  }
}
