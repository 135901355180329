.summary {
  width: 800px;
  margin: 0 auto;
  overflow: auto;
  padding: 50px 0 100px;
}

.summary__title {
  margin-bottom: 30px;
  font-size: 30px;
  color: $color-primary;
}

.summary__subtitle {
  font-family: 'Source Sans Pro Semibold';
  font-size: 20px;
  color: $color-text--dark;
}

@media (max-width: $screen-sm) {
  .summary {
    width: 100%;
    padding: 20px;
  }
}
