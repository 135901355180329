.mocked-element {
  color: #d2d6de!important;
  font-style: italic;
}
.mocked-element-dark {
  color: #6e7582 !important;
  font-style: italic;
}

// Hide any element with v-cloak attribute until the Vue app is loaded:
[v-cloak]:not([vue-loading]) {
  display: none;
}
// To display something (like a loader) until the Vue app is loaded, set both v-cloak and vue-loading attribute
[vue-loading]:not([v-cloak]) {
  display: none;
}

.panel-loading {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 40px;
}
