.main-nav {
  display: flex;

  a {
    padding: 0 18px;

    &:last-of-type {
      border-right: none;
    }

    &:hover,
    &:active,
    &:focus {
      color: #fff;
      transition: ease-in .1s;
    }
  }

  .dropdown {
    &:last-of-type {
      .dropdown-toggle {
        border-right: none;
      }
    }

    .dropdown-toggle {
      border-right: solid 1px rgba(255, 255, 255, .8);
    }

    .dropdown-menu {
      width: 850px;
      left: -230px;
    }

    .dropdown-menu__column {
      display: flex;
      flex-direction: column;
    }
  }
}

@media (max-width: $screen-lg) {
  .main-nav {
    .dropdown {
      .dropdown-menu {
        width: 500px;
      }
    }
  }
}
