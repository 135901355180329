.forgot-password {
  padding: 30px 200px;

  h1 {
    margin: 0 0 30px;
  }

  .form-group {
    margin: 30px 0 10px;
  }

  .btn {
    margin-bottom: 30px;
  }
}

@media (max-width: $screen-md) {
  .forgot-password {
    padding: 30px 50px;
  }
}
