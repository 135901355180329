.carousel {
  height: 365px;
}

.carousel-inner {
  height: 100%;
  background-color: #e5e5e5;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;

  .item {
    height: 100%;

    img {
      height: 100% !important;
      margin: 0 auto;
    }
  }

  .image-count {
    position: absolute;
    bottom: 15px;
    left: 50%;
    transform: translateX(-50%);
  }
}

.carousel-control {
  width: 45px;
  height: 85px;
  top: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 1;
  background: #fff !important;
  transform: translateY(-50%);

  &:hover,
  &:focus {
    opacity: 1;
  }

  &.left {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }

  &.right {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }

  .icon {
    text-shadow: none;
    color: #00081b;
  }
}

@media (max-width: $screen-sm) {
  .carousel {
    height: 230px;
  }
}
