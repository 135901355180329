.mobile-nav {
  background-color: $background-header--dark;
  position: fixed;
  top: 0;
  right: -350px;
  height: 100%;
  width: 90%;
  max-width: 330px;
  opacity: 0;
  visibility: hidden;
  z-index: 999;

  nav {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
}

.mobile-nav--expand {
  opacity: 1;
  visibility: visible;
  right: 0;
  transition: ease-in .1s;
}

.mobile-nav__n2 {
  display: flex;
  flex-direction: column;
}

.mobile-nav__top {
  display: flex;
  border-bottom: solid 1px rgba(255, 255, 255, .2);
  height: 70px;

  .previous {
    display: flex;
    align-items: center;
    flex: 1;
    color: $color-primary;
    font-size: 18px;
    padding: 0 28px;

    .icon {
      margin-right: 10px;
    }
  }

  .close {
    width: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #e7e9eb;
    border-left: solid 1px rgba(255, 255, 255, .8);

    &:hover,
    &:active,
    &:focus {
      opacity: .2;
    }
  }
}

.mobile-nav__main {
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 0 28px;
  overflow-y: scroll;

  a {
    color: #9fabb6;
    font-size: 18px;
    padding: 10px 0;

    &:first-of-type {
      padding: 22px 0 10px;
    }
  }

  .main-link {
    border-bottom: solid 1px rgba(255, 255, 255, .2);
    height: 70px;
    display: flex;
    align-items: center;

    &:first-of-type {
      padding: 10px 0;
    }

    &:last-of-type {
      border-bottom: none;
    }

    .icon {
      margin-right: 15px;
    }
  }
}

.mobile-nav__bottom {
  height: 80px;
  display: flex;
  background-color: $color-primary;

  .place-ad,
  .watchlist {
    justify-content: center;
    align-items: center;
  }

  .place-ad {
    flex: 1;
    border-right: solid 1px rgba(255, 255, 255, .2);
  }

  .watchlist {
    width: 40%;
  }
}

@media (min-width: $screen-sm-max) {
  .mobile-nav {
    display: none;
  }
}

