.filter-select {
  display: flex;
  align-items: center;

  label {
    margin: 0 12px 3px 0;
    color: $color-text;
    font-weight: normal;
    white-space: nowrap;
    line-height: 30px;

    .icon {
      position: relative;
      top: -1px;
      font-size: 8px;
    }
  }
}

.filter-select__form {
  display: flex;
  align-items: center;

  select {
    padding: 0 15px 0 0;
    width: auto;
    max-width: 200px;
    font-family: 'Source Sans Pro Semibold';
    font-size: 14px;
    color: $color-text--dark;
    border: none;
    background-color: transparent;
    -webkit-appearance: none;
    -moz-appearance: none;
    z-index: 2;
  }

  .icon {
    position: relative;
    left: -10px;
    font-size: 8px;
    color: $color-text--dark;
    text-indent: 0;
    z-index: 1;
  }
}

@media (max-width: $screen-sm-max) {
  .filter-select {
    flex-direction: column;
    align-items: flex-start;
  }

  .filter-select__form {
    select {
      max-width: unset;
    }
  }
}
