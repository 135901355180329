.select2 {}

.select2-container--default {
  .select2-selection--single,
  .select2-selection--multiple {
    border: solid 1px $input-border !important;
  }
}

.select2-selection__choice {
  display: flex;
  flex-direction: row-reverse;
}

.select2-selection__choice__remove {
  margin-right: 0;
  margin-left: 7px;
}
