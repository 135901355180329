.content-page {
  color: $color-text;

  .breadcrumb {
    margin-bottom: 0;
    padding: 28px 0;
  }

  .breadcrumb__item:last-of-type {
    a {
      text-decoration: none;
    }
  }

  .shadow-container__content {
    padding: 5px 40px;
  }

  h3 {
    font-family: 'Source Sans Pro Semibold';
    font-size: 15px;
    color: $color-text;
    text-transform: uppercase;
  }

  ul {
    margin-bottom: 30px;
  }

  a {
    text-decoration: underline;
  }

  .links {
    margin-bottom: 40px;
    display: flex;
    align-items: center;

    .btn {
      margin: 7px 10px 7px 0;
      text-decoration: none;
    }
  }

  .links--stacked {
    margin: 20px 0 30px;
    flex-direction: column;
    align-items: flex-start;
  }
}

@media (max-width: $screen-sm) {
  .content-page {
    padding: 0;

    .container {
      padding: 0;
    }

    .shadow-container__content {
      padding: 5px 15px;
    }

    .links {
      flex-direction: column;
      align-items: flex-start;
    }
  }
}
