.category-info {
  margin-top: 35px;
  color: $color-text;

  h3 {
    font-size: 24px;
  }

  h4 {
    font-size: 20px;
  }

  h5 {
    font-size: 15px;
    text-transform: uppercase;
  }

  h6 {
    font-family: 'Source Sans Pro Semibold';
    font-size: 16px;
  }
}
