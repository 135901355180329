.edit-page {
  a {
    text-decoration: none;
  }

  .form-control--large {
    max-width: unset;
  }

  .product-contact {
    margin-top: 30px;
    padding: 0 0 20px;
  }

  .shadow-container__footer {
    .btn {
      margin-left: auto;
    }
  }
}

.edit-page__breadcrumb {
  margin: 15px 0;
  flex-direction: column;
  align-items: flex-start;

  .breadcrumb {
    padding: 15px 0;
  }
}

.edit-page__recap {
  label {
    width: 85px;
  }
}

.edit-page__description {
  .form-control {
    height: 260px;
  }
}
