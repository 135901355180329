.list-results {
  display: flex;
  flex-direction: column;
  flex: 1;

  .title-block {
    padding: 10px 0 22px;
  }

  > a {
    margin: 0 0 15px auto;
    color: $color-text;
    text-decoration: underline;
  }

  .buttons {
    margin: 5px 0 15px;
    display: flex;
    justify-content: space-between;

    .list-button {
      width: 49%;
    }
  }
}

@media (max-width: $screen-xs-max) {
  .list-results {
    .buttons {
      flex-direction: column;

      .list-button {
        width: 100%;
      }
    }

    .title-block {
      padding: 10px 0 10px;

      .title {
        margin: auto;
      }
    }

    .filter-block {
      padding-top: 5px;
      order: 2;
      margin: 0;
      margin-top: 10px;
    }
    .machine_offer_list {
      order: 1;
    }
    .category-info-block {
      order: 3;
    }
  }
}
