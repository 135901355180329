.ribbon {
  padding: 6px;
  width: 200px;
  position: absolute;
  top: 14px;
  left: -70px;
  font-family: 'Source Sans Pro Semibold';
  color: #fff;
  text-transform: uppercase;
  text-align: center;
  transform: rotate(-45deg);
  background-color: #f46f3b;
}
