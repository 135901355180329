.mobile-burger {
  width: 68px;
  background-color: $background-header--dark;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 10px;
  text-transform: uppercase;
  cursor: pointer;

  &:hover,
  &:active,
  &:focus {
    color: #fff;
  }

  .icon {
    font-size: 27px;
  }

  &:before {
    content: "";
    position: absolute;
    left: -12px;
    top: 0;
    width: 0;
    height: 0;
    border-bottom: 70px solid $background-header--dark;
    border-left: 12px solid transparent;
  }
}

@media (min-width: $screen-md) {
  .mobile-burger {
    display: none;
  }
}
