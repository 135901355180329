.contact-modal {
  z-index: 2000;

  .modal-body {
    padding: 45px;
  }

  h3 {
    margin-bottom: 30px;
    font-family: 'Source Sans Pro Semibold';
    font-size: 20px;
    text-align: center;
  }

  .form-control {
    padding: 22px 17px;
  }
}

@media (max-width: $screen-xs-max) {
  .contact-modal {
    .modal-body {
      padding: 15px 15px 60px;
    }
  }
}
