h2 {
  color: $color-title;
}

.title-highlight {
  margin: 20px 0 25px;
  font-family: 'Source Sans Pro Regular';
  font-size: 19px;
  color: $color-primary;
  text-transform: uppercase;
}

.title-line {
  margin: 25px 0 20px;
  position: relative;
  display: flex;
  align-items: center;

  &:before {
    width: 100%;
    height: 1px;
    position: absolute;
    top: 50%;
    left: 0;
    background-color: $color-border;
    transform: translateY(-50%);
    content: '';
    z-index: 1;
  }

  h2 {
    margin: 0;
    padding-right: 15px;
    font-family: 'Source Sans Pro Regular';
    font-size: 19px;
    color: $color-primary;
    text-transform: uppercase;
    background-color: #fff;
    position: relative;
    z-index: 2;
  }
}

