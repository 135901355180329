.impersonation {
  background-color: #374850;
  padding: 0 25px;
  width: 100%;
  min-height: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  a {
    padding: 3px 12px;
    font-size: 14px;
    color: #fff;
    background-color: #dd4b39;
    border-color: #dd4b39;
    border-radius: 5px;

    &:hover {
      background-color: darken(#dd4b39, 5%);
      border-color: darken(#dd4b39, 5%);
      transition: ease-in .15s;
    }
  }
}

.impersonation__name {
  margin-bottom: 0;
  color: #fff;
}


.sidebar-mini,
.sidebar-collapse {
  .impersonation__name {
    margin-bottom: 0;
    color: #fff;
  }
}

.sidebar-mini {
  .impersonation__name {
    margin-left: 230px;
  }
}

.sidebar-collapse {
  .impersonation__name {
    margin-left: 50px;
  }
}
