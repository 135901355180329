.home-page {
  &.content {
    padding-bottom: 50px;
  }
}

@media (max-width: $screen-sm-max) {
  .home-page {
    .title-block {
      padding: 20px 0;
    }

    .card--highlight {
      cursor: pointer;
    }
  }
}
