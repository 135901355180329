.image-count {
  padding: 0 10px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Source Sans Pro Semibold';
  color: #fff;
  background-color: rgba(0, 0, 0, .7);
  border-radius: 4px;

  .icon {
    margin-right: 10px;
    font-size: 18px;
  }
}
