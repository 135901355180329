.bullet-number {
  padding: 2px 13px;
  color: #fff !important;
  background-color: #9aa1a9;
  border-radius: 50px;
}

.bullet-number--primary {
  background-color: $color-primary;
}

a.bullet-number--primary:hover,
a.bullet-number--primary:active,
a.bullet-number--primary:focus {
  background-color: darken($color-primary, 10%) !important;
}

@media (max-width: $screen-sm) {
  .bullet-number {
    padding: 1px 11px;
  }
}
