.category-nav {
  padding: 15px 0;
  background-color: #fff;
  box-shadow: 0 2px 7px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  margin-bottom: 30px;

  .col-sm-6:first-of-type {
    border-right: 1px solid $color-border;
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }
}

.category-nav__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.category-nav__toggle-button {
  display: none;
}

.category-nav__item {
  margin-bottom: 5px;

  a {
    color: $color-text;
  }
}

@media (max-width: $screen-sm-max) {
  .category-nav {
    padding: 0;
    .category-nav__columns {
      display: block;
      max-height: 0; // Utilisez max-height pour l'animation
      overflow: hidden;
      transition: max-height 0.5s ease, opacity 0.5s ease; // Animez max-height et opacity
      opacity: 0;
    }
    .category-nav__columns.active {
      max-height: 100vh; // Définissez la hauteur maximale sur 100vh
      opacity: 1;
    }
    .category-nav__header {
      padding: 5px 0;
    }

    .mobile-nav-categories--expand {
      display: block;
    }
    .category-nav__toggle-button {
      display: block;
      text-align: center;
      padding: 10px 0;
      cursor: pointer;
    }

    .category-nav__icon {
      font-size: 20px;
    }
  }
}
