.extra-link {
  margin-bottom: 7px;
  padding-left: 10px;
  display: flex;
  position: relative;
  color: $color-link;
  text-decoration: underline;

  &:before {
    position: absolute;
    top: 5px;
    left: 0;
    content: '\e90f' !important;
    font-family: 'icomoon';
    font-size: 8px;
  }

  &:hover,
  &:active,
  &:focus {
    color: $color-text;
  }
}
