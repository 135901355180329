.manufacturer-description {
  padding: 15px 0;
  background-color: #fff;
  box-shadow: 0 2px 7px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  margin-bottom: 30px;
}

.manufacturer-description__title {
  font-size: 20px;

  font-weight: 800;
  margin-bottom: 10px;
  color: #343a40;
}

.see-more-content {
  display: none;
}

.manufacturer-description__content {
  font-size: 16px;
  color: #6c757d;
}
.manufacturer-description__more {
  font-size: 16px;
  color: #007bff;
  cursor: pointer;
  display: inline-block;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
}

@media (max-width: $screen-sm) {
  .manufacturer-description {
    display: none;
  }
}
