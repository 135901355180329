.shadow-container {
  background-color: #fff;
  box-shadow: 0 2px 7px rgba(0, 0, 0, .1);
}

.shadow-container__header {
  padding: 0 15px;
  height: 70px;
  display: flex;
  align-items: center;
  box-shadow: 0 2px 7px rgba(0, 0, 0, .19);

  .title {
    margin: 0 15px;
    font-family: 'Source Sans Pro Semibold';
    font-size: 19px;
    color: $color-text--dark;
    align-self: center;
  }
}

.shadow-container__footer {
  margin-top: 30px;
  padding: 0 30px 30px;
  width: 100%;
  justify-content: space-between;

  hr {
    border-top: solid 1px $color-border;
  }

  .inner-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .btn {
      .icon {
        font-size: 11px;
      }
    }

    .button-back {
      .icon {
        margin-right: 10px;
      }
    }

    .button-next {
      margin-left: auto;

      .icon {
        margin-left: 10px;
      }
    }
  }
}

@media (max-width: $screen-md) {
  .shadow-container__header {
    .btn {
      display: none;
    }
  }
}

@media (max-width: $screen-sm) {
  .shadow-container__footer {
    .inner-footer {
      justify-content: center;

      .button-back {
        display: none;
      }

      .button-next {
        margin: 0 auto;
      }
    }
  }
}
