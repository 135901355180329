html,
body {
  height: 100%;
}

.app {
  height: 100%;

  > div {
    height: 100%;
    display: flex;
    flex-direction: column;
  }
}

.app__inner {
  height: 100%;
  display: flex;
  flex-direction: column;
}

body {
  display: flex;
  flex-direction: column;
  font-family: "Source Sans Pro Regular";
  font-size: 15px;
  color: $color-text--dark;
}

.content {
  flex: 1 0 auto;
  padding-bottom: 80px;
  position: relative;
  background-color: $background;
  transition: margin-top 0.5s ease-out;
}

.content-with-banner {
  margin-top: 70px;
}

.asterisk {
  font-size: initial;
  top: -2px;
}

a {
  color: #419de8;

  &:hover,
  &:active,
  &:focus {
    color: darken(#419de8, 5%);
  }
}

.flex-right {
  margin-left: auto;
}
.flex-left {
  margin-right: auto;
}

@keyframes slideDown {
  from {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(0);
  }
}

@media (min-width: $screen-lg) {
  .container {
    width: 1110px;
  }
}
