.account-menu {
  display: flex;
  align-items: center;

  .menu-item, .dropdown {
    border-left: solid 1px rgba(255, 255, 255, .2);
    padding: 0 28px 0 18px;
  }

  .dropdown {
    margin-left: 18px;

    .dropdown-toggle {
      .icon {
        font-size: 14px;
        margin-right: 7px;
      }
    }

    .dropdown-menu {
      right: 0;
      left: unset;
      width: 200px;
    }

    &.open {
      .dropdown-menu {
        display: flex;
        flex-direction: column;
      }
    }
  }
}
