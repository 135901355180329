.contacts-page {
  .account-filter__inner {
    padding-top: 0;
  }

  .filter-search {
    margin: 0;
    margin-left: auto;
    width: 240px;
  }
}
