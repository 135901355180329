.modal-account {
  form {
    .checkbox {
      color: #6f7a86;
    }

    .validation {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      padding: 30px 0;

      > p {
        margin-top: 20px;
      }
    }
  }
}

.modal-account__link {
  margin: 25px 0 30px;
  border-top: 1px solid #d9d9d9;
  padding-top: 30px;
  text-align: center;
  font-size: 19px;
  font-weight: bold;
}
