.message-page {
  font-size: 16px;
  color: $color-text;

  .form-bloc {
    padding-bottom: 30px;
  }

  .btn--fixed {
    display: none;
  }
}

@media (max-width: $screen-md) {
  .message-page {
    .btn--fixed {
      display: flex;
    }
  }
}
