.login {
  padding: 0 50px;

  .btn {
    align-self: center;
  }
}

.login__title {
  font-family: 'Source Sans Pro Semibold';
  font-size: 26px;
  font-weight: bold;
}

.login__extra-link {
  margin: 20px 0;
  border-top: 1px solid #d9d9d9;
  padding-top: 30px;
  text-align: center;
  font-size: 19px;
  font-weight: bold;
}
