.sticky-buttons {
  height: 60px;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  box-shadow: 0 -2px 7px rgba(0, 0, 0, .1);

  button,
  .alert-button {
    background-color: $color-primary;
    border-top: none;
    border-right: solid 1px rgba(255, 255, 255, .5) !important;
    border-bottom: none;
  }

  .alert-button,
  a,
  button {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Source Sans Pro Semibold';
    font-size: 16px;
    color: #fff;

    &:hover,
    &:active,
    &:focus {
      text-decoration: none;
    }

    .icon {
      font-size: 22px;
      margin-right: 15px;
    }
  }

  a {
    height: 100%;
    flex: 1;
  }


  button {
    flex: 2;
  }

  .alert-button {
    flex: 3;
  }
}
