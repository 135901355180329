.form-group-read {
  margin-bottom: 5px;

  .control-label { padding-top: 5px; }

  .form-control {
    line-height: 1.3;
    border: 1px solid transparent;
    height: unset;
    min-height: 31px;

    &.form-outline { border: 1px solid $input-border; }
  }
}

.form-control {
  box-shadow: none;
  border: solid 1px $input-border;
  color: $color-form;

  &::placeholder {
    color: $color-form;
  }

  &:focus {
    outline: none;
    box-shadow: none;

    &::placeholder {
      opacity: .85;
      transition: opacity ease-in .1s;
    }
  }
}

.form-control--primary {
  border: solid 1px $color-primary;

  &:focus {
    outline: none;
    box-shadow: none;
  }
}

.form-control--premium {
  border: solid 1px #031c2f;

  &:focus {
    outline: none;
    box-shadow: none;
  }
}

.form-control--auction {
  border: solid 1px $color-auction !important;

  &:focus {
    outline: none;
    box-shadow: none;
  }
}

.form-control--primary {
  border: solid 1px $color-primary;

  &:focus {
    outline: none;
    box-shadow: none;
  }
}

.form-control--premium {
  border: solid 1px #031c2f;

  &:focus {
    border: solid 1px #031c2f;
    outline: none;
    box-shadow: none;
  }
}

.form-control--auction {
  border: solid 1px $color-auction;

  &:focus {
    border: solid 1px $color-auction;
    outline: none;
    box-shadow: none;
  }
}
