.table-ads-mobile {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
}

.table-ads-mobile__row {
  padding: 15px;
  position: relative;
  display: flex;
  flex: 1;
  font-size: 16px;
  color: $color-table;
  border-top: solid 1px $color-border;

  &:hover,
  &:active,
  &:focus {
    color: $color-text;
    text-decoration: none;
  }

  &:last-of-type {
    border-bottom: solid 1px $color-border;
  }

  &:nth-of-type(odd) {
    background-color: #f9fafb;
  }
}

.table-ads-mobile__head {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  .btn {
    margin-right: 20px;
  }

  .check {
    height: 15px;
  }
}

.table-ads-mobile__image {
  width: 120px;
  min-width: 100px;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
  margin-right: 20px;
}

.table-ads-mobile__premium {
  position: absolute;
  left: 25px;
  width: 30px;

  button {
    height: 47px;
    width: 35px; 
    background: url('../../images/not-premium.png');
    background-size: cover;

    &:not([disabled]):hover {
      background: url('../../images/premium.png');
      background-size: cover;
    }
    &[disabled] {
      cursor: not-allowed;
    }
  }
}

.table-ads-mobile__content {
  flex: 1;

  .header {
    margin-bottom: 15px;
    display: flex;

    .title {
      font-family: 'Source Sans Pro Semibold';
      font-size: 18px;
      color: #001226;
      line-height: 1.2;
    }

    button {
      margin-left: auto;
      padding: 0 0 0 20px;
      align-self: flex-start;
      font-size: 20px;
      color: #6f7a86;
    }
  }

  .footer {
    margin-top: 15px;
    display: flex;
    flex-wrap: wrap;

    > div {
      margin-right: 10px;
      margin-bottom: 5px;
      display: flex;
      align-items: center;
      font-size: 15px;

      .bullet-number {
        margin-right: 5px;
      }
    }
  }
}

.table-ads-mobile__checkbox {
  display: flex;
  align-items: center;

  .check {
    height: 15px;
  }
}

@media (min-width:  $screen-md) {
  .table-ads-mobile {
    display: none;
  }
}
