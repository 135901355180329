footer {
  margin-top: auto;
  padding: 0 25px;
  height: 80px;
  max-height: 80px;
  display: flex;
  flex: 1 0 auto;
  align-items: center;
  color: rgba(255, 255, 255, .5);
  background-color: #142a3b;

  .copyright {
    padding-right: 25px;
    height: 100%;
    display: flex;
    align-items: center;
    border-right: solid 1px rgba(255, 255, 255, .2);
  }

  ul {
    margin: 0;
    padding: 0;
    display: flex;
    list-style: none;
  }

  nav {
    padding: 0 0 0 20px;
    display: flex;
    align-items: center;

    ul {
      flex-wrap: wrap;
    }

    li {
      padding: 3px 0;
      border-right: solid 1px rgba(255, 255, 255, .2);

      &:last-of-type {
        border-right: none;
      }
    }

    a {
      padding: 0 10px;
      color: rgba(255, 255, 255, .5);
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;

      &:hover,
      &:active,
      &:focus {
        color: rgba(255, 255, 255, .5);
        text-decoration: underline;
      }
    }
  }

  .social {
    margin-left: auto;

    li {
      margin: 0 15px;

      &:last-of-type {
        margin-right: 0;
      }
    }

    a {
      font-size: 20px;
      color: rgba(255, 255, 255, .7);

      &:hover,
      &:active,
      &:focus {
        color: rgba(255, 255, 255, .8);
        text-decoration: none;
      }
    }
  }
}

@media (max-width: $screen-sm-max) {
  footer {
    padding: 55px 0;
    height: unset;
    max-height: unset;
    font-size: 16px;
    flex-direction: column;

    .copyright {
      margin-bottom: 30px;
      padding: 0;
      color: rgba(255, 255, 255, .8);
      border-right: none;
    }

    nav {
      margin-bottom: 30px;

      ul {
        flex-direction: column;
        text-align: center;

        li {
          margin-bottom: 15px;
          border: none;
        }
      }
    }

    .social {
      margin-left: unset;

      li:last-of-type {
        margin-right: 10px;
      }
    }
  }
}
