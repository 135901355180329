.form-bloc {
  padding: 0 30px;
}

.form-bloc__title {
  margin: 35px 0 20px;
  padding-bottom: 15px;
  font-family: 'Source Sans Pro Semibold';
  font-size: 15px;
  color: $color-text--dark;
  text-transform: uppercase;
  border-bottom: solid 1px $color-border;
}

.form-bloc__title--light {
  padding-bottom: 0;
  border-bottom: none;
}

.form-bloc__help {
  margin: 15px 0; 
  font-size: 15px;
  color: $color-text;
}

.form-bloc__error {
  color: $color-danger;
  padding: 5px;
}

.form-bloc__row {
  margin: 5px 0 15px;
  display: flex;
  align-items: center;

  label {
    width: 150px;
    font-family: 'Source Sans Pro Semibold';
    font-size: 15px;
    color: $color-text--dark;
  }

  .form-control {
    flex: 1 0 auto;
    max-width: 330px;
    font-size: 15px;
  }

  .help {
    margin-left: 30px;
    color: $color-text;
  }
}

.form-bloc__row--stacked {
  flex-direction: column;
  align-items: flex-start;

  label {
    margin: 0 0 10px;
  }

  .form-control {
    max-width: unset;
    flex: unset;
  }
}

.form-bloc--striped {
  padding: 0;

  .form-bloc__title {
    margin: 30px 20px;
    padding-bottom: 0;
    border-bottom: none;
  }

  .form-bloc__row {
    margin: 0;
    padding: 25px 35px;
    flex-direction: column;
    align-items: flex-start;
    border-top: solid 1px $color-border;

    &:nth-of-type(odd) {
      background-color: #fbfbfc;
    }

    > div {
      margin-bottom: 15px;
      display: flex;

      &:last-of-type {
        margin-bottom: 0;
      }

      > label {
        width: 160px;
        font-family: 'Source Sans Pro Semibold';
        font-size: 15px;
      }

      .form-control {
        width: 320px;
      }

      .breadcrumb {
        padding: 0;
        flex: 1 0 auto;
        color: $color-text;
      }

      .radio-line {
        display: flex;
        align-items: center;

        .radio {
          margin-right: 20px;
        }
      }
    }

    .input-daterange {
      display: flex;

      .form-control {
        width: 150px;

        &:first-of-type {
          margin-right: 15px;
        }
      }
    }
  }

  .form-bloc__row--vertical {
    > div {
      flex-direction: column;

      > label {
        margin-bottom: 10px;
      }
    }

    textarea.form-control {
      height: 300px;
      min-width: 100%;
    }
  }
}

@media (max-width: $screen-sm) {
  .form-bloc {
    padding: 0 20px;
  }

  .form-bloc__title {
    margin: 30px 0 20px;
    font-size: 16px;
    text-align: center;
  }

  .form-bloc__help {
    margin: 0 0 20px;
  }

  .form-bloc__row {
    flex-direction: column;
    align-items: flex-start;

    label {
      margin: 0 0 10px;
    }

    .form-control {
      max-width: unset;
    }

    .help {
      margin: 15px 0 0;
    }
  }

  .form-bloc--striped {
    padding: 0;

    .form-bloc__title {
      text-align: center;
    }

    .breadcrumb {
      display: inline-block;
    }

    .form-bloc__row {
      padding: 25px 20px;

      > div {
        width: 100%;
        flex-direction: column;

        > label {
          margin-bottom: 10px;
        }

        .form-control {
          width: unset;
          min-width: unset;
        }
      }

      .input-daterange {
        width: 100%;

        .form-control {
          width: unset;
          flex: 1;

          &:first-of-type {
            margin-right: 16px;
          }
        }
      }
    }
  }
}
