.message-info {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  font-size: 14px;

  > div {
    margin-bottom: 5px;
    width: 50%;
    display: flex;
    color: $color-text--dark;

    label {
      width: 130px;
      min-width: 130px;
      color: $color-text;
      font-weight: normal;
    }

    > span {
      display: flex;
      flex-direction: column;
    }
  }
}

.message-info--wrap {
  height: 150px;
}

@media (max-width: $screen-sm) {
  .message-info {
    > div {
      width: 100%;
    }
  }

  .message-info--wrap {
    height: unset;
  }
}
