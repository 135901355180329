.place-ad {
  display: flex;
  align-items: center;
  color: rgba(255, 255, 255, .8);
  border-left: solid 1px rgba(255, 255, 255, .2);
  border-right: solid 1px rgba(255, 255, 255, .2);

  &:hover,
  &:active,
  &:focus {
    color: rgba(255, 255, 255, 1);
    transition: color ease-in .1s;
  }

  .icon {
    margin-right: 10px;
    font-size: 38px;
  }

  > span {
    display: flex;
    flex-direction: column;
  }
}
