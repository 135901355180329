.table-ads {
  width: 100%;
  font-size: 16px;
  color: $color-table;

  tbody {
    tr {
      &:nth-of-type(even) {
        background-color: #f9fafb;
      }

      &:first-of-type {
        &:hover {
          background-color: #fff;
          cursor: default;
        }
      }

      &:hover {
        background-color: #f1f8fe;

        .table-ads__product {
          .description {
            .title {
              color: $color-primary;
              text-decoration: underline;
            }
          }
        }

        .table-ads__product--auction {
          .description {
            .title {
              color: $color-auction;
            }
          }
        }

        .table-ads__product--premium {
          .description {
            .title {
              color: $color-premium;
            }
          }
        }
      }

      th,
      td {
        text-align: center; 

        button {
          padding: 0;
        }

        .check {
          height: 15px;

          label {
            padding-left: 15px;
          }
        }
      }

      th {
        padding: 0 5px;

        button[type="submit"] {
          &:hover,
          &:active,
          &:focus {
            .icon {
              color: darken(#7d7d7f, 5%); 
              transition: color ease-in .2s;
            }
          }

          .icon {
            font-size: 18px !important;
            color: #7d7d7f; 
          }
        }
      }

      td {
        &:first-of-type,
        &:last-of-type {
          padding: 0 20px;
        }

        a {
          color: $color-table;

          &:hover,
          &:active,
          &:focus {
            color: $color-table;
            text-decoration: none;
          }
        }
      }
    }
  }
}

.table-ads__premium {
  img {
    width: 30px;
  }

  .flag {
    height: 37px;
    width: 30px;
    background: url('../../images/not-premium.png');
    background-size: cover;

    &:hover {
      background: url('../../images/premium.png');
      background-size: cover;
    }

    &[disabled] {
      cursor: not-allowed;
    }
  }

  .flag--auction {
    &:hover {
      background: url('../../images/auction.png');
      background-size: cover;
    }
  }
}

.table-ads__product-head {
  text-align: left !important;
}

.table-ads__checkbox-head {
  padding: 0 !important;
}

.table-ads__product {
  width: 340px;
  padding: 10px 0;

  .image {
    display: table-cell;
    margin-right: 20px;
    width: 110px;
    min-width: 110px;
    height: 80px;
    background-repeat: no-repeat !important;
    background-position: center !important;
    background-size: cover !important;
  }

  .description {
    padding-left: 20px;
    display: table-cell;
    text-align: left;
    vertical-align: middle;

    .title {
      display: table-cell;
      font-family: 'Source Sans Pro Semibold';
      font-size: 17px;
      color: $color-text--dark;
    }

    .info {
      max-width: 170px;
      font-size: 15px;
    }
  }
}

@media (max-width: $screen-sm-max) {
  .table-ads {
    display: none;
  }
}
