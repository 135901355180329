button {
  background: transparent;
  border: none;

  &:focus {
    outline: none !important;
    box-shadow: none !important;
  }
}

.btn {
  padding: 10px 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  color: #fff;
  border-radius: 50px;

  &:hover,
  &:active,
  &:focus {
    transition: ease-in .1s;
    background-color: transparent !important;
    box-shadow: none !important;
  }

  .icon {
    margin-right: 9px;
  }
}

.btn--squared {
  padding: 6px 10px !important;
  font-size: 14px;
  border-radius: 5px;
}

.btn--icon {
  .icon {
    margin-right: 0;
  }
}

.btn--primary {
  background-color: $color-primary;

  &:hover,
  &:active,
  &:focus {
    color: $color-primary;
    border-color: $color-primary;
  }
}

.btn--premium {
  background-color: $color-premium;

  &:hover,
  &:active,
  &:focus {
    color: $color-premium;
    border-color: $color-premium;
  }
}

.btn--auction {
  background-color: $color-auction;

  &:hover,
  &:active,
  &:focus {
    color: $color-auction !important;
    border-color: $color-auction !important;
  }
}

.btn--danger {
  background-color: $color-danger;

  &:hover,
  &:active,
  &:focus {
    color: $color-danger;
    border-color: $color-danger;
  }
}

.btn--empty {
  color: $color-text--dark;
  border-color: $color-text--dark;

  &:hover,
  &:active,
  &:focus {
    border-color: $color-text;
    color: $color-text;
  }
}

.btn--fixed {
  height: 55px;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  display: none;
  align-items: center;
  justify-content: center;
  border-radius: 0;

  a {
    font-family: 'Source Sans Pro Semibold';
    color: #fff;
  }

  &.btn--primary {
    color: #fff;
    background-color: $color-primary !important;

    a:hover {
      background-color: $color-primary;
    }
  }

  &.btn--premium {
    color: #fff;
    background-color: $color-premium !important;

    a:hover {
      background-color: $color-premium;
    }
  }

  &.btn--auction {
    color: #fff;
    background-color: $color-auction !important;

    a:hover {
      background-color: $color-auction;
    }
  }
}

.btn--fixed {
  height: 55px;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0;

  a,
  button {
    font-family: 'Source Sans Pro Semibold';
    color: #fff;
  }

  &.btn--primary {
    color: #fff;
    background-color: $color-primary !important;

    a,
    button {
      &:hover {
        color: #fff;
        background-color: $color-primary;
      }
    }
  }

  &.btn--success {
    color: #fff;
    background-color: $color-success !important;

    a,
    button {
      &:hover {
        background-color: $color-success;
      }
    }
  }

  &.btn--premium {
    color: #fff;
    background-color: $color-premium !important;

    a,
    button {
      &:hover {
        background-color: $color-premium;
      }
    }
  }

  &.btn--auction {
    color: #fff;
    background-color: $color-auction !important;

    a,
    button {
      &:hover {
        background-color: $color-auction;
      }
    }
  }
}

@media (max-width: $screen-xs-max) {
  .btn--fixed {
    display: flex;
  }
}
