.category-last-offers__title {
  margin: 10px 0 27px;
  font-family: "Source Sans Pro Semibold";
  font-size: 22px;
}

.category-last-offers__item {
  margin: 0 0 30px;
}

@media (max-width: $screen-sm-max) {
  .category-last-offers__item {
    margin: 0 0 20px;
  }

  .category-last-offers__title {
    margin: 0;
    font-size: 18px;
  }
}
