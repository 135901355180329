.pricing {
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

.pricing__item {
  max-width: 340px;
  margin: 0 10px;
  flex: 1;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 7px rgba(0, 0, 0, .19);

  &:first-of-type {
    margin-left: 0;
  }

  &:last-of-type {
    margin-right: 0;
  }
}

.pricing__header {
  margin-bottom: 13px;
  padding: 20px 45px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: 'Source Sans Pro Semibold';
  border-bottom: solid 1px $color-border;

  .title {
    font-size: 18px;
    color: $color-primary;
    text-align: center;
  }

  .subtitle {
    max-width: 100%;
    margin-top: 7px;
    display: inline-block;
    font-size: 15px;
    color: $color-text--dark;
    text-align: center;
  }

  img {
    width: 30px;
    position: absolute;
    top: -2px;
    right: 20px;
  }
}

.pricing__advantage {
  margin: 0 25px 13px;
  font-size: 15px;
  color: $color-text;
  display: flex;
  line-height: 1.3;

  .icon {
    margin-right: 15px;
    position: relative;
    top: 2px;
    font-size: 14px;
    color: $color-success;
  }

  span {
    max-width: 90%;
    display: inline-block;
  }
}

.pricing__offer {
  padding: 15px 25px;
  display: flex;
  justify-content: space-between;
  border-top: solid 1px $color-border;

  .radio {
    label {
      padding-left: 35px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      font-family: 'Source Sans Pro Semibold';
      color: $color-text--dark;

      span {
        margin-top: 2px;
        display: block;
        font-family: 'Source Sans Pro Regular';
        color: $color-text;
        font-style: italic;
      }
    }
  }

  .price {
    font-family: 'Source Sans Pro Semibold';
    color: $color-primary;
  }
}

.pricing__footer {
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-top: solid 1px $color-border;

  .price {
    margin-bottom: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: 'Source Sans Pro Semibold';
    font-size: 18px;
    color: $color-text--dark;

    span {
      font-family: 'Source Sans Pro Regular';
      font-size: 15px;
      color: $color-text;
      font-style: italic;
    }
  }

  .conditions {
    max-width: 100%;
    margin-top: 20px;
    display: inline-block;
    font-family: 'Source Sans Pro Regular';
    font-size: 15px;
    color: $color-text;
    text-align: center;

    a {
      font-family: 'Source Sans Pro Semibold';
      color: $color-primary;
      text-decoration: underline;
    }
  }
}

@media (max-width: $screen-md) {
  .pricing {
    flex-direction: column;
  }

  .pricing__item {
    width: 100%;
    max-width: unset;
    margin: 0 0 20px;
  }

  .pricing__header {
    margin-bottom: 0;
  }

  .pricing__advantage {
    display: none;
  }

  .pricing__offer {
    border-top: none;
    border-bottom: solid 1px $color-border;
  }

  .pricing__footer {
    border-top: none;
  }
}
