.step {
  margin: 0 auto;
  position: relative;
  display: flex;

  &:after {
    width: 100%;
    height: 1px;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    background-color: #9aa1a9;
    content: '';
  }
}

.step__item {
  margin: 0 25px;
  padding: 0 15px;
  position: relative;
  display: flex;
  align-items: center;
  font-family: 'Source Sans Pro Semibold';
  color: $color-step;
  background-color: #fff;
  z-index: 2;

  &:first-of-type {
    margin-left: 0;
  }

  &:last-of-type {
    margin-right: 0;
  }

  .icon,
  .number {
    margin-right: 10px;
    width: 25px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    background-color: #9aa1a9;
    border-radius: 50px;
  }

  .icon {
    display: none;
  }
}

.step__item--active {
  color: $color-text--dark;

  .number {
    background-color: $color-primary;
  }
}

.step__item--done {
  color: $color-text--dark;
  cursor: pointer;

  .icon {
    position: absolute;
    left: 15px;
    top: 0;
    display: flex;
    font-size: 10px;
    background-color: $color-primary;

    &:before {
      position: relative;
      top: 1px;
    }
  }
}

@media (max-width: $screen-md-max) {
  .step__item {
    margin: 0 15px;
    padding: 0 10px;

    .number {
      margin-right: 10px;
    }
  }

  .step__item--done {
    .icon {
      left: 10px;
    }
  }
}

@media (max-width: $screen-sm-max) {
  .step {
    &:after {
      display: none;
    }
  }

  .step__item {
    margin: 0 11px;
    padding: 0;

    .name {
      display: none;
    }
  }

  .step__item--done {
    .icon {
      left: 0;
    }
  }
}
