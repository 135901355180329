.your-profile {
  display: flex;
  flex-wrap: wrap;

  .form-bloc {
    width: 50%;

    &:nth-of-type(3) {
      width: 100%;
      display: flex;
      flex-wrap: wrap;

      .form-bloc__title {
        width: 100%;
      }

      .form-bloc__row {
        width: 50%;
        display: inline-flex;

        &:first-of-type {
          select {
            margin-bottom: 25px;
          }
        }

        &:nth-of-type(odd) {
          padding-right: 30px;
        }

        &:nth-of-type(even) {
          padding-left: 30px;
        }
      }
    }

    textarea {
      height: 215px;
    }

    label {
      text-align: left;
    }
  }

  .selected-industry {
    margin: 0 0 0;
    color: $color-form;
  }

  .select2 {
    margin-top: 15px;
  }

  .edit-button {
    margin-top: 15px;
    color: $color-form;
    text-decoration: underline;
    padding-left: 22px;
    position: relative;

    &:before {
      position: absolute;
      top: 3px;
      left: 0;
      font-family: 'icomoon';
      font-size: 12px;
      content: '\e909';
    }
  }
}

@media (max-width: $screen-sm-max) {
  .your-profile {
    .form-bloc {
      width: 100%;

      &:nth-of-type(3) {
        .form-bloc__row {
          padding: 0 !important;
          width: 100%;
        }
      }
    }

    textarea {
      height: 300px;
    }
  }
}
