.accordion {
  margin: 20px 0 30px;
  width: 480px;
  border: solid 1px $color-border;
  border-radius: 5px;
}

@media (max-width: $screen-sm) {
  .accordion {
    width: 100%;
  }
}
