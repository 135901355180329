.panel {
  margin: 0;
  box-shadow: none;
  border: none;
}

.panel-heading {
  height: 55px;
  padding: 6px 23px;
  display: flex;
  align-items: center;
  font-family: 'Source Sans Pro Semibold';
  font-size: 15px;
  color: $color-text--dark;
  border-bottom: solid 1px $color-border;

  .check {
    position: relative;
    top: -7px;
  }

  a {
    padding: 10px 5px;
    position: relative;
    display: flex;
    flex: 1;
    align-items: center;
    color: $color-text--dark;

    &:not(.no-collapse-display):after {
      position: absolute;
      top: 50%;
      right: 5px;
      content: '\e91d';
      font-family: 'icomoon';
      font-size: 8px;
      color: $color-text;
      transform: translateY(-50%);
    }

    &:hover,
    &:active,
    &:focus {
      text-decoration: none;
    }

    &[aria-expanded="true"] {
      &:after {
        content: '\e91c';
      }
    }
  }

  .bullet-number {
    margin-left: 7px;
  }
}

.panel-collapse {
  border-bottom: solid 1px $color-border;
}

.panel-body {
  padding-left: 85px;
  background: #fbfbfc;
}

.panel-row {
  margin-bottom: 7px;
  display: flex;
  align-items: top;
  flex-wrap: wrap;

  a {
    &:after {
      font-family: 'icomoon';
      font-size: 8px;
      margin-left: 7px;
      position: relative;
      top: -2px;
      color: #808080;
    }

    &[aria-expanded="false"] {
      &:after {
        content: '\ea0a';
        font-size: 10px;
        color: $color-primary;
        top: 0;
      }
    }

    &[aria-expanded="true"] {
      &:after {
        content: '\ea0b';
        font-size: 10px;
        color: $color-primary;
        top: 0;
      }
    }
  }

  .category-level {
    font-family: 'Source Sans Pro Regular';
    color: $color-text;
    max-width: 330px;
    word-break: break-word;
    flex: 1;

    &:hover,
    &:active,
    &:focus {
      text-decoration: none;
    }

    &.last {
      width: 100%;
      min-width: 100%;

      > div {
        padding: 10px 0 10px 31px;
        display: flex;
        flex-direction: column;

        .check {
          margin-bottom: 7px;
          top: 0;

          label {
            font-weight: lighter;
          }
        }
      }
    }
  }
}

@media (max-width: $screen-xs) {
  .panel-body {
    padding-left: 55px;
  }
}
